import angular from 'angular';
import {
  DATE_DISPLAY_FORMAT,
  ID_PREFIX,
  ODATA_DATE_ONLY_FORMAT,
  ODATA_DATE_TIME_FORMAT,
  PATH_TO_API
} from "../constants";
import {asArray} from "../general/helpers";
import io from 'socket.io-client'
import {
  cleanObj,
  getSafely, isFunction,
  isNonEmptyArray, isNonEmptyObject,
  isNonEmptyString,
  isNumber,
  setIfHasValue
} from "@tomeravni/easybizy-js-common/common";

var pathToODataApi = 'odata/';
var pathToApi = PATH_TO_API;
dataModule.$inject = ['$http', 'CacheFactory', '$q', '$timeout', 'productsPickerRepository'];

export default function dataModule($http, CacheFactory, $q, $timeout, productsPickerRepository) {
  var cacheKey = 'httpOdataCache';
  var custom$HttpCache = CacheFactory(cacheKey, {
    maxAge: 60 * 60 * 1000, // Items added to this cache expire after 15 minutes
    cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour
    deleteOnExpire: 'aggressive' // Items will be deleted from this cache when they expire
  });

  custom$HttpCache.removeByName = function (name) {
    if (!angular.isString(name)) {
      return;
    }

    var cache = this;
    var queries = [];
    var meaningFulNamePart = name.replace(/([A-Z]+)/g, ",$1").replace(/^,/, "").split(",")[0].toLowerCase();
    angular.forEach(cache.keySet(), function (query) {
      if (query.toLowerCase().indexOf(meaningFulNamePart) > -1) {
        queries.push(query);
      }
    });

    angular.forEach(queries, function (query) {
      cache.remove(query);
    });
  };

  custom$HttpCache.removeExact = function (url) {
    if (!angular.isString(url)) {
      return;
    }

    var cache = this;
    angular.forEach(cache.keySet(), function (query) {
      if (query.indexOf(url) > -1) {
        cache.remove(query);
      }
    });
  };

  $http.defaults.cache = custom$HttpCache;

  //    $http.defaults.cache = cache;
  var repositoryWrapper = function () {
  };

  repositoryWrapper.Entity = function (entityType, ignoreCache) {
    var that = {};
    that.entityType = entityType;
    var deferred;

    that.availableApi = {
      query: query,
      create: create,
      update: update,
      createLink: createLink,
      deleteLink: deleteLink,
      remove: remove,
      patch: patch
    };

    return that.availableApi;

    function query(decorateEntityMethod, decorateListMethod) {
      if (!that.entityType) {
        throw new Error("Query must be obtained on a specific entity");
      }

      var concreteQuery = new Query(() => getBaseQuery(that.entityType));
      concreteQuery.entity(that.entityType);
      concreteQuery.get = executeQuery;
      that.decorateEntityMethod = decorateEntityMethod;
      that.decorateListMethod = decorateListMethod;
      return concreteQuery;
    }

    function executeQuery(decorateEntity) {
      if (deferred) {
        deferred.resolve();
      }

      var myDeferred = deferred = $q.defer();
      var deferredToReturn = $q.defer();

      var thisContext = this;

      const url = this.getQuery();
      $http({
        url: url,
        method: 'GET',
        timeout: myDeferred.promise,
        cache: (thisContext.idFilter || ignoreCache) ? false : custom$HttpCache
      }).then(function ({data}) {
        const total = parseInt(data['odata.count']);

        if (Array.isArray(data)) {
          data = {results: data};
        } else if (Array.isArray(data.value)) {
          data = {results: data.value};
        } else {
          data = {results: [data]};
        }

        if (isNumber(total)) {
          data.total = total;
        }


        var decoration = angular.isDefined(decorateEntity) ? decorateEntity :
          angular.isDefined(that.decorateEntityMethod) ? that.decorateEntityMethod : null;

        data.results = data.results.map(decoration ? decoration : function (x) {
          x.EntityType = that.entityType;
          return x;
        });


        if (thisContext.idFilter) { // if the request was by id, return single entity.
          //otherwise, return an array (regardless how many entities returned)
          data = data.results[0];
        }

        data.value = data.results;

        deferredToReturn.resolve(angular.isDefined(that.decorateListMethod) ? that.decorateListMethod(data) : data);
      }).catch(function (data, status, headers, config) {
        deferredToReturn.reject(data);
      });

      return deferredToReturn.promise;
    }

    function create(entity) {
      return writeEntity(entity, "POST");
    }

    function update(entity) {
      return writeEntity(entity, "PUT");
    }

    function patch(id, fields) {
      var dummyEntity = {};
      dummyEntity[that.entityType + ID_PREFIX] = id;
      return writeEntity(dummyEntity, "PATCH", fields);
    }

    function remove(entityOrEntities, givenUrl) {
      var url = getBaseQuery(that.entityType);// + "(" + entity[that.entityType + ID_PREFIX] + ")";
      var entitiesToDelete = null;
      if (angular.isArray(entityOrEntities)) {
        entitiesToDelete = {};
        url += "/DeleteMultiple";
        var keys = [];
        if (entityOrEntities.length > 0 && typeof entityOrEntities[0] === "number") {
          keys = entityOrEntities;
        } else {
          angular.forEach(entityOrEntities, function (entity) {
            keys.push(entity[that.entityType + ID_PREFIX]);
          });
        }

        entitiesToDelete.keys = keys;
      } else {
        url += "(" + (typeof entityOrEntities === "number" ? entityOrEntities : entityOrEntities[that.entityType + ID_PREFIX]) + ")";
      }

      return post(url,
        entitiesToDelete ? "POST" : "DELETE",
        entitiesToDelete ? entitiesToDelete : null);
    }

    function createLink(entity, navigationProperty, entityTypeTolink, entityIdTolink) {
      var url = getBaseQuery(that.entityType) + "(" + entity[that.entityType + ID_PREFIX] + ")/$links/" + navigationProperty;
      var linkUrl = {"url": getBaseQuery(entityTypeTolink) + "(" + entityIdTolink + ")"};
      return post(url, "PUT", linkUrl);
    }

    function deleteLink(entity, navigationProperty, entityTypeTolink, entityIdTolink) {
      var url = getBaseQuery(that.entityType) + "(" + entity[that.entityType + ID_PREFIX] + ")/$links/" + navigationProperty;
      var linkUrl = {"url": getBaseQuery(entityTypeTolink) + "(" + entityIdTolink + ")"};
      return post(url, "DELETE", linkUrl);
    }

    function writeEntity(entity, action, body /*Used only for patch*/) {
      var url = (action === "PUT" || action === "PATCH") ? getBaseQuery(that.entityType) + "(" + entity[that.entityType + ID_PREFIX] + ")" :
        getBaseQuery(that.entityType);


      if (!that.entityType) {
        throw new Error("Query must be obtained on a specific entity");
      }

      var concreteQuery = new Query(() => getBaseQuery(that.entityType));
      concreteQuery.entity(that.entityType);
      concreteQuery.get = executeQuery;
      concreteQuery.post = function () {
        return post(url, action, body ? body : entity, concreteQuery);
      };

      return concreteQuery;
    }

    function post(url, action, value, concreteQuery) {
      if (!that.entityType) {
        throw new Error("Query must be obtained on a specific entity");
      }

      var deferred = $q.defer();
      $http({
        url: url,
        method: action,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(value)
      }).then(function ({data}) {
        if (concreteQuery && concreteQuery._expand) {
          var idToFind = data ? data : value;
          concreteQuery.id(idToFind[that.entityType + ID_PREFIX]).get().then(deferred.resolve);
        } else {
          deferred.resolve(data);
        }
      }).catch(function (err) {
        if (!err) {
          deferred.reject(err);
        }

        const {data} = err;
        deferred.reject(data['odata.error'] ? parseOdataError(data['odata.error']) : data);
      });

      custom$HttpCache.removeByName(that.entityType);
      return deferred.promise;
    }

    function parseOdataError(error) {
      return error.innererror ?
        (error.innererror.internalexception ?
          error.innererror.internalexception.message
          : error.innererror.message) : error.message.value;
    }

    function getBaseQuery(entity) {
      return baseUrl + pathToODataApi + owl.pluralize(entity);
    }
  };

  /*
  * General query Object.
  * */
  function Query(getBaseQuery, iIgnoreOdata) {
    var convention = '_';
    var odataKeyWordsConvertor = {
      'take': 'top',
      'skip': 'skip',
      'filter': 'filter',
      'orderby': 'orderby',
      'expand': 'expand',
      'select': 'select',
      'inlinecount': 'inlinecount'
    };

    var that = this;
    var lastResultedUrl;
    this._filters = [];
    this._ids = [];
    this._tags = [];
    this._onlineCount = false;
    this._customQueryParams = null;

    this.clear = function (all) {
      this._take = undefined;
      this._skip = undefined;
      this._filters.length = 0;
      this._ids.length = 0;

      if (all) {
        this._orderby = undefined;
        this._customOrderBy = undefined;
        this._expand = undefined;
      }
    };

    this.entity = function (i_entity) {
      this._entity = i_entity;
    };

    this.take = this.top = function (i_take) {
      this._take = i_take;
      return this;
    };

    this.skip = function (i_skip) {
      this._skip = i_skip;
      return this;
    };

    this.orderBy = function (i_orderBy, decending) {
      var self = this;
      self._orderby = '';
      var splittedOrderBy = i_orderBy.split(',');
      splittedOrderBy.forEach(function (orderItem, idx) {
        self._orderby += orderItem + (decending ? "%20desc" : "") + ((idx < splittedOrderBy.length - 1) ? ',' : '');
      });

      return this;
    };

    this.expand = function (i_expand) {
      this._expand = i_expand;
      return this;
    };

    this.id = function (id) {
      this._filters.push(that._entity + "Id" + " eq " + id);
      this.idFilter = true;
      return this;
    };

    this.select = function (select) {
      this._select = select;
      return this;
    };

    this.filter = function (filter) {
      if (Array.isArray(filter)) {
        this._filters.pushAll(filter);
      } else {
        this._filters.push(filter);
      }

      return this;
    };

    this.setFilter = function (filter) {
      if (!filter) {
        this._filters = [];
      } else {
        this._filters = Array.isArray(filter) ? filter : [filter];
      }

      return this;
    };

    this.queryParams = function (name, value) {
      if (!this._customQueryParams) {
        this._customQueryParams = {};
      }

      if (!value) {
        delete this._customQueryParams[name];
      } else {
        this._customQueryParams[name] = value;
      }

      return this;
    };

    this.ids = function (ids) {
      if (Object.prototype.toString.call(ids) === '[object Array]') {
        this._ids.pushAll(ids);
      } else {
        this._ids.push(ids);
      }
    };

    this.tags = function (tags) {
      this._tags = tags ? tags : [];
    };

    this.setOnlineCount = (val) => {
      this._inlinecount = val ? 'allpages' : '';
    }

    this.getQuery = function () {
      var finalUrl = this.getBaseQuery(that._entity);
      finalUrl = appendMultiple(finalUrl, 'filter');
      finalUrl = appendCustom(finalUrl, '_customQueryParams');
      finalUrl = appendParams(finalUrl, 'selectedIds', this._ids);
      finalUrl = appendTags(finalUrl, this._tags);
      finalUrl = appendSingle(finalUrl, 'take');
      finalUrl = appendSingle(finalUrl, 'skip');
      finalUrl = appendSingle(finalUrl, 'select');
      finalUrl = appendSingle(finalUrl, 'orderby');
      finalUrl = appendSingle(finalUrl, 'expand');
      finalUrl = appendSingle(finalUrl, 'inlinecount');


      lastResultedUrl = finalUrl;
      return finalUrl;
    };

    this.getBaseQuery = getBaseQuery;

    /**
     This method takes the single property and use it by changing the plural. For instance
     It the param is "filter" then it will look for "filters", combine them and push into "filter"
     */
    function appendMultiple(url, property) {
      var actualProperty = owl.pluralize(property);
      if (!that[convention + actualProperty]) {
        return url;
      }

      var arrayOfFilters = that[convention + actualProperty];
      if (Object.prototype.toString.call(arrayOfFilters) !== '[object Array]') {
        return url;
      }

      var finalString = "";
      $.each(arrayOfFilters, function (index, value) {
        var toAppend = "";
        if (index > 0) {
          finalString += " and (";
          toAppend = ")";

        }

        finalString += value + toAppend;
      });

      that[convention + property] = finalString;

      return appendSingle(url, property);
    }

    function appendSingle(url, property, ignoreOdata) {
      if (!that[convention + property]) {
        return url;
      }

      if (url.indexOf("?") === -1) {
        url += "?";
      } else {
        url += "&";
      }

      if (!ignoreOdata && !iIgnoreOdata) {
        url += "$";
      }

      url += odataKeyWordsConvertor[property] + "=" + that[convention + property];

      return url;
    }

    function appendCustom(url, property) {
      if (!that[property] || (Array.isArray(that[property]) && that[property].length === 0) || Object.keys(that._customQueryParams).length === 0) {
        return url;
      }

      if (url.indexOf("?") === -1) {
        url += "?";
      } else {
        url += "&";
      }

      var first = true;
      $.each(that._customQueryParams, function (key, value) {
        if (!first) {
          url += "&";
        } else {
          first = false;
        }

        url += key + (key.length > 0 ? "=" : '') + value;
      });

      return url;
    }

    function appendParams(url, name, values) {
      if (!values || values.length == 0) {
        return url;
      }

      if (url.indexOf("?") === -1) {
        url += "?";
      } else {
        url += "&";
      }

      url += name + "={";
      var len = values.length;
      for (var i = 0; i < len; i++) {
        url += values[i];
        if (i < len - 1) {
          url += ", ";
        }
      }

      url += "}";

      return url;
    }

    function appendTags(url, values) {
      if (!values || values.length == 0) {
        return url;
      }

      if (url.indexOf("?") === -1) {
        url += "?";
      } else {
        url += "&";
      }

      url += "selectedTags={TagIds:[";
      var len = values.length;
      for (var i = 0; i < len; i++) {
        url += values[i];
        if (i < len - 1) {
          url += ", ";
        }
      }

      url += "]}";

      return url;
    }
  }

  repositoryWrapper.Custom = function (type) {
    if (type === 'CustomerDetails') {
      return new EntityDetailsRepository(new generalHttpExecutor(true), "Customer");
    } else if (type === 'LeadDetails') {
      return new EntityDetailsRepository(new generalHttpExecutor(true), "Lead");
    } else if (type === "ProductOrServiceDetails") {
      return new EntityDetailsRepository(new generalHttpExecutor(true), "ProductOrService");
    } else if (type === "EmployeeDetails") {
      return new EntityDetailsRepository(new generalHttpExecutor(true), "Employee");
    } else if (type === "QueryableTable") {
      return new QueryableTableRepository(new generalHttpExecutor(true), "QueryableTable");
    } else if (type === 'ServiceMetadataIngredients') {
      return new ServiceMetadataIngredients($http, custom$HttpCache);
    } else if (type === 'EmployeeHours') {
      return new EmployeeHours(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'PromotionsRepository') {
      return new PromotionsRepository($http);
    } else if (type === 'ReportsRepository') {
      return new ReportsRepository($http, new generalHttpExecutor());
    } else if (type === 'EntitiesLazyRepository') {
      return new EntitiesLazyRepository($http, custom$HttpCache, new generalHttpExecutor());
    } else if (type === 'CategoriesEditorRepository') {
      return new CategoriesEditorRepository($http, custom$HttpCache, new generalHttpExecutor());
    } else if (type === 'CashRegister') {
      return new CashRegisterRepository($http, custom$HttpCache, new generalHttpExecutor());
    } else if (type === 'CashRegisterActivities') {
      return new CashRegisterActivitiesRepository(new generalHttpExecutor());
    } else if (type === 'Configurations') {
      return new ConfigurationsRepository($http, new generalHttpExecutor());
    } else if (type === 'Feedback') {
      return new FeedbackRepository($http);
    } else if (type === 'CustomersFeedback') {
      return new CustomersFeedbackRepository($http, new generalHttpExecutor());
    } else if (type === 'Dashboard') {
      return new DashboardRepository(new generalHttpExecutor());
    } else if (type === 'PriceQuote') {
      return new PriceQuoteRepository($http);
    } else if (type === 'GiftCard') {
      return new GiftCardRepository($http);
    } else if (type === 'CustomTimelineItem') {
      return new CustomTimelineItemRepository($http, new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'General') {
      return new GeneralRepository($http);
    } else if (type === 'Calendar') {
      return new CalendarRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'Search') {
      return new SearchRepository(new generalHttpExecutor());
    } else if (type === 'CustomersGroup') {
      return new CustomersGroupRepository(new generalHttpExecutor());
    } else if (type === 'Membership') {
      return new MembershipRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'Version') {
      return new VersionRepository(new generalHttpExecutor());
    } else if (type === 'Leads') {
      return new LeadsRepository(new generalHttpExecutor(), $q);
    } else if (type === 'TemplateMessages') {
      return new TemplateMessagesRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'EmployeeSales') {
      return new EmployeeSalesRepository(new generalHttpExecutor());
    } else if (type === 'Expenses') {
      return new ExpensesRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'ResourceManagement') {
      return new ResourceManagementRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'Tutorials') {
      return new TutorialsRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'CustomerSubscription') {
      return new CustomerSubscriptionRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'IncomingCalls') {
      return new IncomingCallsRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'CommonSubscriptions') {
      return new CommonSubscriptionsRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'CommonGiftCards') {
      return new CommonGiftCardsRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'PriceLists') {
      return new PriceListsRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'LeadSubStatus') {
      return new LeadSubStatusRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'LeadCategory') {
      return new LeadCategoryRepository(new generalHttpExecutor(), custom$HttpCache);
    } else if (type === 'OnlineScheduling') {
      return new OnlineScheduling(new generalHttpExecutor(), custom$HttpCache, $q);
    } else if (type === 'Forms') {
      return new Forms(new generalHttpExecutor());
    } else if (type === 'WhatsNew') {
      return new WhatsNew(new generalHttpExecutor(), $http);
    } else if (type === 'PCI') {
      return new PCI(new generalHttpExecutor(), $http);
    } else if (type === 'VOIP') {
      return new VOIP(new generalHttpExecutor());
    } else if (type === 'Themes') {
      return new Themes(new generalHttpExecutor());
    } else if (type === 'Sessions') {
      return new SessionsRepository(new generalHttpExecutor(), $http);
    } else if (type === 'Conversations') {
      return new ConversationsRepository(new generalHttpExecutor());
    } else if (type === 'PublicApi') {
      return new PublicApiRepository(new generalHttpExecutor());
    } else if (type === 'IsraelInvoice') {
      return new IsraelInvoiceRepository($http);
    } else {
      throw "No repository of this type yet.";
    }
  };

  return repositoryWrapper;

  function generalHttpExecutor(ignoreCache) {
    this.execute = this.get = function (url, preventCache, withCredentials) {
      const requestConfig = {
        url: url,
        method: 'GET',
        cache: !preventCache && !ignoreCache
      };

      if (withCredentials) {
        requestConfig.withCredentials = true;
      }

      return $http(requestConfig).then((res) => res ? res.data : null)
    };

    this.post = function (url, value, callbackOnSuccess, withCredentials) {
      const cancelPromise = $q.defer();

      const requestConfig = {
        url: url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        timeout: cancelPromise.promise,
        data: value ? JSON.stringify(value) : {}
      };

      if (withCredentials) {
        requestConfig.withCredentials = true;
      }
      return $http(requestConfig).then(function ({data}) {
        if (isFunction(callbackOnSuccess)) {
          callbackOnSuccess(data);
        }

        return data;
      });
    };

    this.put = function (url, value, withCredentials) {
      const deferred = $q.defer();

      const requestConfig = {
        url: url,
        method: "PUT",
        dataType: 'json',
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(value)
      };

      if (withCredentials) {
        requestConfig.withCredentials = true;
      }

      $http(requestConfig).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    };

    this.delete = function (url, value, withCredentials) {
      const deferred = $q.defer();

      const requestConfig = {
        url: url,
        method: "DELETE",
        dataType: 'json',
        headers: {
          "Content-Type": "application/json"
        },
      };

      setIfHasValue(requestConfig, 'data', value);

      if (withCredentials) {
        requestConfig.withCredentials = true;
      }


      $http(requestConfig).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    };

    this.patch = function (url, value, withCredentials) {
      const deferred = $q.defer();

      let requestConfig = {
        url: url,
        method: "PATCH",
        dataType: 'json',
        headers: {
          "Content-Type": "application/json"
        },
        data: value
      };

      if (withCredentials) {
        requestConfig.withCredentials = true;
      }

      $http(requestConfig).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    };

  }

  function EntityDetailsRepository(httpExecuter, entityType) {
    return {
      info: info,
      data: data,
      nextMeetings: nextMeetings,
      customTimelineItemsFile: customTimelineItemsFile,
      mergeCustomers: mergeCustomers,
      nextCustomer: nextCustomer,
      easybizyCustomerPayment: easybizyCustomerPayment,
      customerCatalog: customerCatalog
    };

    function info(id, type) {
      var url = baseUrl + pathToApi + entityType + "Details/Info/" + id + (angular.isDefined(type) ? "?type=" + type : "");
      return httpExecuter.execute(url);

    }

    function data(id, type) {
      var url = baseUrl + pathToApi + entityType + "Details/Data/" + id + (angular.isDefined(type) ? "?type=" + type : "");
      return query(url);
    }

    function nextMeetings(id) {
      var url = baseUrl + pathToApi + entityType + "Details/NextMeetings/" + id;
      return httpExecuter.execute(url);
    }

    function customTimelineItemsFile(id) {
      var url = baseUrl + pathToApi + entityType + "Details/CustomTimelineItemsFile/" + id;
      window.location = url;
    }

    function mergeCustomers(id, idToMerge) {
      var url = baseUrl + pathToApi + entityType + "Details/Merge";
      return httpExecuter.put(url, {
        id: id,
        idToMerge: idToMerge
      });
    }

    function nextCustomer(id) {
      var url = baseUrl + pathToApi + entityType + "Details/NextCustomer?id=" + id;
      return httpExecuter.execute(url);
    }

    function easybizyCustomerPayment(id) {
      var url = baseUrl + pathToApi + entityType + "Details/EasybizyCustomerPayment?id=" + id;
      return httpExecuter.execute(url);
    }

    function customerCatalog(id) {
      var url = baseUrl + pathToApi + entityType + "Details/CustomerCatalog?id=" + id;
      return httpExecuter.execute(url);
    }

    function query(externalUrl) {
      return new function () {
        var executeUrl = externalUrl;
        var paramsToConcatenate = this.params = {};

        this.skip = function (i_skip) {
          paramsToConcatenate.skip = "$skip=" + i_skip;
          return this;
        };

        this.top = function (i_top) {
          paramsToConcatenate.top = "$top=" + i_top;
          return this;
        };

        this.filter = function (typeOrTypes) {
          var hasTypeFilter = typeOrTypes != null && typeOrTypes.length > 0;
          if (!hasTypeFilter) {
            paramsToConcatenate.filter = null;
            return;
          }

          paramsToConcatenate.filter = "$filter=";

          if (typeOrTypes instanceof Array) {
            for (var index = 0; index < typeOrTypes.length; index++) {
              if (index > 0) {
                paramsToConcatenate.filter += " or ";
              }

              paramsToConcatenate.filter += extractTypeFilter(typeOrTypes[index]);

            }

          } else {
            paramsToConcatenate.filter = extractTypeFilter(typeOrTypes);
          }

          return this;
        };

        function extractTypeFilter(typeFilter) {
          return "(Type eq '" + (!angular.isObject(typeFilter) ? (typeFilter + "'") : (typeFilter.value + "' and Category eq '" + typeFilter.category + "'")) + ")";
        }

        this.orderBy = function (orderValue, decending) {
          paramsToConcatenate.orderby = "$orderby=" + orderValue + (decending ? " desc" : "");
          return this;
        };

        this.get = function () {
          var url = executeUrl;
          if (Object.keys(paramsToConcatenate).length > 0) {
            angular.forEach(paramsToConcatenate, function (value) {
              if (url.indexOf('?') < 0) {
                url += "?";
              } else {
                url += "&";
              }

              url += value;
            });
          }

          return httpExecuter.execute(url).then(data => {
            if (Object.prototype.toString.call(data) === '[object Array]' || Object.prototype.toString.call(data.value) === '[object Array]') {
              data.results = data.value;
            } else {
              data = {results: [data]};
            }

            return data;
          });
        };
      };
    }
  }

  function QueryableTableRepository(httpExecuter, entityType) {
    return {
      meetingClosing: meetingClosing,
      meetingHistory: meetingHistory,
      inventoryHistory: inventoryHistory,
      customerVisitHistory: customerVisitHistory,
      customerMeetings: customerMeetings,
      customerInvoices: customerInvoices,
      customerPurchases: customerPurchases,
      customers: customers,
      abandonedCustomers: abandonedCustomers,
      cancelledCustomers: cancelledCustomers,
      customersWithDebt: customersWithDebt,
      leads: leads,
      groupedServicesCost: groupedServicesCost,

    };

    function meetingClosing() {
      var url = baseUrl + pathToApi + "MeetingClosings";
      return query(url, true);
    }

    function meetingHistory(meetingId) {
      if (meetingId) {
        var url = baseUrl + pathToApi + "MeetingClosings/SingleMeetingHistory";
      } else {
        var url = baseUrl + pathToApi + "MeetingClosings/MeetingsHistory";
      }
      return query(url);
    }

    function groupedServicesCost() {
      var url = baseUrl + pathToApi + "MeetingClosings/GroupedServicesCost";
      return query(url);
    }

    function inventoryHistory() {
      var url = baseUrl + pathToApi + "InventoryHistory";
      return query(url);
    }

    function customerVisitHistory() {
      var url = baseUrl + pathToApi + "CustomerDetails/CustomerVisitDetails";
      return query(url);
    }

    function customerMeetings() {
      var url = baseUrl + pathToApi + "CustomerDetails/CustomerMeetingDetails";
      return query(url);
    }

    function customerInvoices() {
      var url = baseUrl + pathToApi + "CustomerDetails/CustomerInvoicesDetails"
      return query(url);
    }

    function customerPurchases() {
      var url = baseUrl + pathToApi + "CustomerDetails/CustomerPurchaseDetails"
      return query(url);
    }

    function customers() {
      var url = baseUrl + pathToApi + "CustomersTable";
      return query(url, true);
    }

    function abandonedCustomers() {
      var url = baseUrl + pathToApi + "CustomersTable/AbandonedCustomers";
      return query(url);
    }

    function cancelledCustomers() {
      var url = baseUrl + pathToApi + "CustomersTable/CancelledCustomers";
      return query(url);
    }

    function customersWithDebt() {
      var url = baseUrl + pathToApi + "CustomersTable/CustomersWithDebts";
      return query(url);
    }

    function leads() {
      var url = baseUrl + pathToApi + "LeadsData/Leads";
      return query(url);
    }

    function query(externalUrl, defaultAction) {
      return new function () {
        var executeUrl = externalUrl;
        var paramsToConcatenate = this.params = {};
        this._filters = [];
        var defaultControllerAction = !!defaultAction;

        this.id = function (i_Id) {
          paramsToConcatenate.id = "id=" + i_Id;
          return this;
        };

        this.from = function (i_from) {
          paramsToConcatenate.from = "from=" + i_from;
          return this;
        };

        this.to = function (i_to) {
          paramsToConcatenate.to = "to=" + i_to;
          return this;
        };

        this.skip = function (i_skip) {
          paramsToConcatenate.skip = "$skip=" + i_skip;
          return this;
        };

        this.top = function (i_top) {
          paramsToConcatenate.top = "$top=" + i_top;
          return this;
        };

        this.filter = function (filter) {
          this._filters.length = 0;

          if (Array.isArray(filter)) {
            this._filters.pushAll(filter);
          } else {
            this._filters.push(filter);
          }

          if (this._filters.length > 0) {
            paramsToConcatenate.filter = "$filter=";

            for (var index = 0; index < this._filters.length; index++) {
              if (index > 0) {
                paramsToConcatenate.filter += " and ";
              }

              paramsToConcatenate.filter += this._filters[index];
            }
          } else {
            paramsToConcatenate.filter = "";
          }

          return this;
        };

        this.orderBy = function (orderValue, decending) {
          paramsToConcatenate.orderby = "$orderby=" + orderValue + (decending ? " desc" : "");
          return this;
        };

        this.get = function () {
          var url = executeUrl;
          url += this.getQuery()

          return httpExecuter.execute(url).then(data => {
            if (Object.prototype.toString.call(data) === '[object Array]' || Object.prototype.toString.call(data.value) === '[object Array]') {
              data.results = data.value;
            } else {
              data = {results: [data]};
            }

            return data;
          });
        };

        this.getQuery = function () {
          var url = '';
          if (Object.keys(paramsToConcatenate).length > 0) {
            angular.forEach(paramsToConcatenate, function (value) {
              if (url.indexOf('?') < 0) {
                url += "?";
              } else {
                url += "&";
              }

              url += value;
            });
          }

          return url;
        };

        this.getInfo = function () {
          var url = executeUrl + (defaultControllerAction ? '/Info' : 'Info');
          url += this.getQuery()

          return httpExecuter.execute(url).then(data => {
            if (Object.prototype.toString.call(data) === '[object Array]' || Object.prototype.toString.call(data.value) === '[object Array]') {
              data.results = data.value;
            } else {
              data = {results: [data]};
            }

            return data;
          });
        };

        this.export = function () {
          var url = executeUrl;
          var urlAddition = defaultControllerAction ? "/Export" : "Export";
          if (url.indexOf('?') > 0) {
            url = url.substring(0, url.indexOf('?')) + urlAddition + url.substring(url.indexOf('?'));
          } else {
            url += urlAddition;
          }
          if (Object.keys(paramsToConcatenate).length > 0) {
            angular.forEach(paramsToConcatenate, function (value) {
              if (value.indexOf('top') < 0 && value.indexOf('skip') < 0) {
                if (url.indexOf('?') < 0) {
                  url += "?";
                } else {
                  url += "&";
                }

                url += value;
              }
            });
          }

          window.location = url;
        };

        this.print = function (dictionary) {
          var url = executeUrl;
          var urlAddition = defaultControllerAction ? "/Print" : "Print";
          if (url.indexOf('?') > 0) {
            url = url.substring(0, url.indexOf('?')) + urlAddition + url.substring(url.indexOf('?'));
          } else {
            url += urlAddition;
          }

          if (Object.keys(paramsToConcatenate).length > 0) {
            angular.forEach(paramsToConcatenate, function (value) {
              if (value.indexOf('top') < 0 && value.indexOf('skip') < 0) {
                if (url.indexOf('?') < 0) {
                  url += "?";
                } else {
                  url += "&";
                }

                url += value;
              }
            });
          }

          return httpExecuter.post(url, dictionary)
            .then(data => {
              return data;
            });
        };
      };
    }


  }

  function ServiceMetadataIngredients($http, custom$HttpCache) {
    const customerIngredientResource = pathToApi + "ServiceMetadataIngridients/CustomerIngredientsUsageRecord/";

    return {
      get: get,
      post: post,
      getCustomerIngredients: getCustomerIngredients,
      remove: remove,
    };

    function get(serviceIngredientId) {
      var deferred = $q.defer();

      var url = baseUrl + pathToApi + "ServiceMetadataIngridients/Data" + (serviceIngredientId ? ("/") + serviceIngredientId : "");
      execute(url).then(function (serviceMetadata) {
        fixEntityTypeRecursively(serviceMetadata);
        deferred.resolve(serviceMetadata);
      })
        .catch(function (err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function post(entities, customerId, extraDetails) {
      var url = baseUrl + pathToApi + "ServiceMetadataIngridients/CustomerIngredients/" + customerId;
      var ingredientsFieldsToSend = asArray(entities).map(ingredientUsageSelection);

      var toSend = angular.copy(extraDetails ? extraDetails : {});
      toSend.IngredientUsages = ingredientsFieldsToSend;

      return execute(url, 'POST', toSend).then((results) => {
        custom$HttpCache.removeExact(customerIngredientResource + customerId);
        return results;
      })
    }

    function ingredientUsageSelection(x) {
      return {
        ProductMetadataId: x.ProductMetadataId,
        Quantity: x.Quantity,
        IngredientModel: x.IngredientModel,
        CustomField1: x.CustomField1,
        CustomField2: x.CustomField2,
        Notes: x.Notes
      };
    }

    function getCustomerIngredients(customerId) {
      var url = baseUrl + customerIngredientResource + customerId;
      return execute(url);
    }

    function remove(customerIngredientId) {
      var url = baseUrl + pathToApi + "ServiceMetadataIngridients/IngredientsUsage/" + customerIngredientId;
      return execute(url, 'DELETE').then((results) => {
        custom$HttpCache.removeByName('CustomerIngredientsUsageRecord');
        return results;
      })
    }

    function execute(url, method, data) {
      var deferred = $q.defer();

      var request = {
        url: url,
        dataType: 'json',
        method: method ? method : 'GET'
      };

      if (data) {
        request.contentType = "application/json; charset=utf-8";
        request.data = JSON.stringify(data);
      }

      $http(request).then(function ({data: serviceMetadata}) {
        deferred.resolve(serviceMetadata);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    function fixEntityTypeRecursively(hasCategories) {
      if (hasCategories.ProductCategories) {
        hasCategories.ProductCategories.forEach(p => fixEntityTypeRecursively(p));
      }
      // Enumerable.From(hasCategories.ProductCategories).ForEach(function (productCategory) {
      //   fixEntityTypeRecursively(productCategory);
      // });

      if (hasCategories.ProductMetadatas) {
        hasCategories.ProductMetadatas.forEach(p => {
          p.EntityType = "ProductMetadata";
          p.expandable = false;
        });
      }

      // Enumerable.From(hasCategories.ProductMetadatas).ForEach(function (productMetadata) {
      //   productMetadata.EntityType = "ProductMetadata";
      //   productMetadata.expandable = false;
      // });
    }
  }

  function EmployeeHours(httpExecuter, $cache) {
    return {
      checkIn: checkIn,
      checkOut: checkOut,
      manualyUpdateOrCreateShift: manualyUpdateOrCreateShift,
      getEmployeeShifts: getEmployeeShifts,
      query: query,
      update: update,
      openShifts: openShifts,
      checkAllOut: checkAllOut,
      exportDataToExcel: exportDataToExcel,
      printShifts: printShifts,
      daysOfWork: daysOfWork,
      daysOfWorkException: daysOfWorkException,
      deleteDaysOfWorkException: deleteDaysOfWorkException,
      notifyShiftsViaSMS: notifyShiftsViaSMS
    };

    function checkIn(employeeId) {
      var url = baseUrl + pathToApi + "EmployeeHours/In?id=" + employeeId;
      $cache.removeByName('Employee');
      return httpExecuter.post(url);
    }

    function checkOut(employeeId) {
      var url = baseUrl + pathToApi + "EmployeeHours/Out?id=" + employeeId;
      $cache.removeByName('Employee');
      return httpExecuter.post(url);
    }

    function manualyUpdateOrCreateShift(employeeId, start, end) {
      var url = baseUrl + pathToApi + "EmployeeHours/Manual?id=" + employeeId + "&start=" + start + "&end=" + end;
      $cache.removeByName('Employee');
      return httpExecuter.post(url);
    }

    function getEmployeeShifts(employeeId, start, end) {
      var url = baseUrl + pathToApi + "EmployeeHours?id=" + employeeId + "&start=" + start + "&end=" + end;
      return httpExecuter.execute(url, true);
    }

    function update(employeeId, createdOrUpdatedShifts, deletedShifts, deletedExceptions, shiftsToCommit) {
      var url = baseUrl + pathToApi + "EmployeeHours";
      $cache.removeByName('Employee');
      return httpExecuter.put(url, {
        EmployeeId: employeeId, UpdatedOrCreated: createdOrUpdatedShifts,
        Deleted: deletedShifts, Committed: shiftsToCommit, DeletedExceptions: deletedExceptions
      });
    }

    function openShifts() {
      var url = baseUrl + pathToApi + "EmployeeHours/CheckedInEmployees";
      return httpExecuter.execute(url);
    }

    function checkAllOut() {
      var url = baseUrl + pathToApi + "EmployeeHours/CheckAllEmployeesOut";
      return httpExecuter.post(url);
    }

    function exportDataToExcel(employeeId, start, end, dictionary, callback) {
      var from = moment(start, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
      var to = moment(end, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
      var url = baseUrl + pathToApi + "EmployeeHours/ExportDataToExcel?id=" + employeeId + "&start=" + from + "&end=" + to + "&dictionary=" + JSON.stringify(dictionary);
      window.location = url;
      if (callback) {
        callback();
      }
    }

    function printShifts(employeeId, start, end) {
      var from = moment(start, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
      var to = moment(end, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
      var url = baseUrl + pathToApi + "EmployeeHours/PrintShifts?id=" + employeeId + "&start=" + from + "&end=" + to;
      return httpExecuter.execute(url);
    }

    function daysOfWork(employeeId, daysOfWorkObject, cancelFutureInlineChanges) {
      var url = baseUrl + pathToApi + "EmployeeHours/DaysOfWork?id=" + employeeId + "&cancelFutureInlineChanges=" + !!cancelFutureInlineChanges;
      $cache.removeByName('Employee');
      return httpExecuter.post(url, daysOfWorkObject);
    }

    function daysOfWorkException(employeeId, daysOfWorkExceptionObject) {
      var url = baseUrl + pathToApi + "EmployeeHours/DaysOfWorkException?id=" + employeeId;
      $cache.removeByName('Employee');
      return httpExecuter.post(url, daysOfWorkExceptionObject);
    }

    function deleteDaysOfWorkException(employeeId, exceptionId) {
      var url = baseUrl + pathToApi + "EmployeeHours/DeleteDaysOfWorkException?employeeId=" + employeeId + "&id=" + exceptionId;
      $cache.removeByName('Employee');
      return httpExecuter.delete(url, exceptionId);
    }

    function notifyShiftsViaSMS(datesRange, employeesId) {
      var url = baseUrl + pathToApi + 'EmployeeHours/SendShiftsToEmployees?start=' + datesRange.from + '&end=' + datesRange.to;
      return httpExecuter.post(url, employeesId);
    }

    function query(employeeId) {
      return new function () {
        var paramsToConcatenate = {};

        this.skip = function (i_skip) {
          paramsToConcatenate.skip = "$skip=" + i_skip;
          return this;
        };

        this.top = function (i_top) {
          paramsToConcatenate.top = "$top=" + i_top;
          return this;
        };

        this.filter = function (from, to, onlyClosedShifts) {
          paramsToConcatenate.filter = "$filter=CheckedIn ge datetime'" + from.format(ODATA_DATE_TIME_FORMAT) + "' and CheckedIn lt datetime'" + to.format(ODATA_DATE_TIME_FORMAT) + "'";
          if (onlyClosedShifts) {
            paramsToConcatenate.filter += " and CheckedOut ne null";
          }
          return this;
        };

        this.orderBy = function (orderValue, decending) {
          paramsToConcatenate.orderby = "$orderby=" + orderValue + (decending ? " desc" : "");
          return this;
        };

        this.params = function () {
          return $.extend({}, paramsToConcatenate);
        };

        this.get = function () {
          var url = baseUrl + pathToApi + "EmployeeHours?id=" + employeeId;
          if (Object.keys(paramsToConcatenate).length > 0) {
            angular.forEach(paramsToConcatenate, function (value) {
              url += "&" + value;
            });
          }

          return httpExecuter.execute(url, true);
        };
      };
    }
  }

  function PromotionsRepository($http) {
    var validEntityFields = ['Filters', 'Repeat', 'PromotionId', 'StartTime', 'ClientPromotionContent', 'PromotionName', 'PresetName', 'IsServiceEngagementType', 'IsForAllCustomers', 'CustomersId', 'DirectRecipients'];

    return {
      query: query,
      getById: getById,
      getPromotions: getPromotions,    // getting the promotions saved in DB, arranged by params.
      getHtmlContent: getHtmlContent,
      getFbContent: getFbContent,
      getSmsContent: getSmsContent,
      getClientPromotion: getClientPromotion,
      create: createPromotion,
      calculate: calculateSmsCost,
      test: testPromotion,
      update: updatePromotion,
      deleteSendingReport: deleteSendingReport,
      restart: restart,
      pause: pause,
      cancel: cancel,
      reschedule: reschedule,
      getTemplate: getTemplate,
      updateTemplateMessage: updateTemplateMessage,
      getFilteredCustomerCount: getfilteredCustomerCount,
      getFilteredCustomersFile: getFilteredCustomersFile,
      filteredCustomer: filteredCustomer,
      smsCountByMonths: smsCountByMonths,
      sendIAmBusySMS: sendIAmBusySMS,
      expandPromotionPreview: expandPromotionPreview,
      getRecurringMeetingReminderCampaign,
    };

    function getById(promotionId) {
      var url = baseUrl + pathToODataApi + "Promotions?$expand=PromotionContent&$filter=PromotionId eq " + promotionId;
      return execute(url);
    }

    function getClientPromotion(promotionId) {
      var url = baseUrl + pathToApi + "/PromotionCreator?id=" + promotionId;
      return execute(url);
    }

    function getHtmlContent(promotionId) {
      var url = baseUrl + pathToApi + "/PromotionCreator/MailHtmlContent/" + promotionId;
      return execute(url, true);
    }

    function getSmsContent(promotionId) {
      var url = baseUrl + pathToApi + "/PromotionCreator/SmsContent/" + promotionId;
      return execute(url, true);
    }

    function getFbContent(promotionId) {
      var url = baseUrl + pathToApi + "/PromotionCreator/FbContent/" + promotionId;
      return execute(url, true);
    }

    function getPromotions(type, promotionTimingType) {
      var url = baseUrl + pathToApi + "PromotionSendingStatus";
      var hasTypeFilter = false;
      if (type && type !== "all") {
        url += "/Extended?$filter=Type eq '" + type + "' ";
        hasTypeFilter = true;
      }

      if (promotionTimingType !== "all") {
        url += (hasTypeFilter ? " and " : "?$filter=");
        url += "Repeat ";
        url += (promotionTimingType === "active" ? "ne null" : "eq null");
      }

      return execute(url);
    }

    function smsCountByMonths() {
      var url = baseUrl + pathToApi + "PromotionSendingStatus/SendingCountByMonths";
      return execute(url);
    }

    function expandPromotionPreview(promotionSendingReportId, customerId) {
      var url = baseUrl + pathToApi + 'CustomerDetails/Preview?customerId=' + customerId + '&promotionSendingReportId=' + promotionSendingReportId;
      return $http({
        url: url,
        method: 'GET'
      }).then(({data}) => data);
    }


    function execute(url, wrap, enableCache) {
      return $http({
        url: url,
        method: "GET",
        cache: angular.isDefined(enableCache) ? enableCache : true
      }).then(function ({data}) {
        if (wrap) {
          return {value: [data]};
        } else {
          return data;
        }
      });
    }

    // The API (for generic component) part:

    function query() {
      return new function () {
        var paramsToConcatenate = {};

        this.skip = function (i_skip) {
          paramsToConcatenate.skip = "$skip=" + i_skip;
          return this;
        };

        this.top = function (i_top) {
          paramsToConcatenate.top = "$top=" + i_top;
          return this;
        };

        this.filter = function (type, promotionTimingType) {
          var hasTypeFilter = false;
          paramsToConcatenate.filter = "";

          if (type && type !== "all") {
            paramsToConcatenate.filter += "/Extended?$filter=Type eq '" + type + "' ";
            hasTypeFilter = true;
          }

          if (promotionTimingType !== "all") {
            paramsToConcatenate.filter += (hasTypeFilter ? " and " : "?$filter=");
            paramsToConcatenate.filter += "Repeat ";
            paramsToConcatenate.filter += (promotionTimingType === "active" ? "ne null" : "eq null");
          }

          return this;
        };

        this.orderBy = function (orderValue, decending) {
          paramsToConcatenate.orderby = "$orderby=" + orderValue + (decending ? " desc" : "");
          return this;
        };

        this.get = function () {
          var url = baseUrl + pathToApi + "PromotionSendingStatus";
          if (Object.keys(paramsToConcatenate).length > 0) {
            if (paramsToConcatenate.hasOwnProperty("filter")) {
              url += paramsToConcatenate.filter;
            }

            var index = 0;
            angular.forEach(paramsToConcatenate, function (value, key) {
              if (key === 'filter') {
                return;
              }

              if (index == 0 && url.indexOf('?') < 0) {
                url += "?";
              } else {
                url += "&";
              }

              url += value;
              index++;
            });
          }

          return execute(url, false, false);
        };
      };
    }

    // End of API Support
    function createPromotion(promotion, ignoreCreation, externalDeferred) {
      cleanEntity(promotion);
      var url = baseUrl + pathToApi + "PromotionCreator";
      return executeEdits(url, "POST", promotion, externalDeferred);
    }

    function testPromotion(promotion, to, externalDeferred) {
      cleanEntity(promotion);
      var url = baseUrl + pathToApi + "PromotionCreator/Test?to=" + to;
      return executeEdits(url, "POST", promotion, externalDeferred);
    }

    function calculateSmsCost(smss) {
      var url = baseUrl + pathToApi + "PromotionCreator/Calculate?smss=" + smss;
      return executeEdits(url, "GET");
    }

    function updatePromotion(clientPromotion) {
      cleanEntity(clientPromotion);
      var url = baseUrl + pathToApi + "PromotionCreator";
      return executeEdits(url, "PUT", clientPromotion);
    }

    function cleanEntity(instance) {
      var fieldsToRemove = [];
      angular.forEach(instance, function (value, key) {
        if ($.inArray(key, validEntityFields) == -1) {
          fieldsToRemove.push(key);
        }
      });

      angular.forEach(fieldsToRemove, function (field) {
        delete instance[field];
      });
    }

    function deleteSendingReport(promotionsSendingReportsToDelete) {
      var url = baseUrl + pathToApi + "PromotionSendingStatus";
      return executeEdits(url, "DELETE", promotionsSendingReportsToDelete);
    }

    function restart(promotionId) {
      var url = baseUrl + pathToApi + "PromotionCreator/Restart?id=" + promotionId;
      return executeEdits(url, "PUT");
    }

    function pause(promotionId) {
      var url = baseUrl + pathToApi + "PromotionCreator/Pause?id=" + promotionId;
      return executeEdits(url, "PUT");
    }

    function cancel(promotionId) {
      var url = baseUrl + pathToApi + "PromotionCreator/Cancel?id=" + promotionId;
      return executeEdits(url, "PUT");
    }

    function reschedule(values) {
      var url = baseUrl + pathToApi + "PromotionCreator/Reschedule";
      return executeEdits(url, "PUT", values);
    }

    function getTemplate(senderType, preset) {
      var url = baseUrl + pathToApi + "PromotionCreator/Template?template=" + preset + "&type=" + senderType;
      return execute(url);
    }

    function updateTemplateMessage(template, type, message) {
      var url = baseUrl + pathToApi + "PromotionCreator/TemplateMessage?template=" + template + "&type=" + type;
      return executeEdits(url, "PUT", message);
    }

    function getfilteredCustomerCount(filters, type) {
      const url = baseUrl + pathToApi + "PromotionCreator/FilteredCustomersCount?type=" + type;
      return executeEdits(url, 'POST', filters);
    }

    function getFilteredCustomersFile(filters, type) {
      var url = baseUrl + pathToApi + "PromotionCreator/FilteredCustomersFile?type=" + type;
      var filtersData = {Filters: JSON.stringify(filters)};
      var deferred = $q.defer();
      $.fileDownload(url, {
        httpMethod: "POST",
        data: filtersData,
        successCallback: function (url) {
          deferred.resolve();
        },
        failCallback: function (responseHtml, url) {
          deferred.reject();
        }
      });

      return deferred.promise;
    }

    function filteredCustomer(type, decorator) {
      var type = type;
      var decorator = decorator;
      return new function () {
        this.paramsToConcatenate = {};
        this.skip = function (i_skip) {
          this.paramsToConcatenate.skip = "$skip=" + i_skip;
          return this;
        };

        this.take = function (i_top) {
          this.paramsToConcatenate.top = "$top=" + i_top;
          return this;
        };

        this.filter = function () {
          // Just for supporting the api.
        };

        this.data = function (data) {
          this.externalFilters = data;
        };

        this.clear = function () {
          delete this.paramsToConcatenate.skip;
          delete this.paramsToConcatenate.top;
        };

        this.get = function () {
          var url = baseUrl + pathToApi + "PromotionCreator/FilteredCustomers?type=" + type;
          url += "&$select=CustomerId,Images/FilePath,FirstName,LastName,Gender&$expand=Images";
          angular.forEach(this.paramsToConcatenate, function (value) {
            url += "&" + value;
          });

          return executeEdits(url, "POST", this.externalFilters, null, decorator);
        };
      };
    }

    function executeEdits(url, method, valuesToSend, externalDeferred, decorator) {
      var deferred = externalDeferred ? externalDeferred : $q.defer();
      $http({
        url: url,
        method: method,
        dataType: 'json',
        data: angular.isDefined(valuesToSend) ? JSON.stringify(valuesToSend) : ""
      }).then(function ({data}) {
        if (decorator) {
          deferred.resolve(decorator(data));
        } else {
          deferred.resolve(data);
        }
      }).catch(function (jqXHR, textStatus, errorThrown) {
        deferred.reject(errorThrown);
      });

      return deferred.promise;
    }

    /*metadata:
     MobileFirst: is mandatory
     CustomerId: (optional)

     regardless of CustomerId, should use the MobileNumber.
     */
    function sendIAmBusySMS(metadata) {
      var url = baseUrl + pathToApi + "PromotionCreator/SendIAmBusySMS";

      var deferred = $q.defer();
      $http({
        url: url,
        method: 'POST',
        dataType: 'json',
        data: JSON.stringify(metadata)
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (jqXHR, textStatus, errorThrown) {
        deferred.reject(errorThrown);
      });

      return deferred.promise;
    }

    function getRecurringMeetingReminderCampaign() {
      const deferred = $q.defer();
      const url = baseUrl + pathToODataApi + 'Promotions?$expand=PromotionSchedule,PromotionContent&$filter=PresetName%20eq%20%27MeetingRemainder%27%20and%20PromotionStatus%20eq%20%27Valid%27and%20PromotionScheduleId%20gt%200%20and%20PromotionSchedule%2FRepeat%20ne%20null'
      execute(url, false, false).then((x) => {
        const campaigns = getSafely(['value'], x);
        if (!isNonEmptyArray(campaigns)) {
          return null;
        }

        const whatsappCampaign = campaigns.find((campaign) =>
          getSafely(['PromotionContent', 'IsGenericWhatsApp'], campaign));

        return whatsappCampaign || campaigns[0];
      }).then((promotion) => {
        if (!isNonEmptyObject(promotion) || !promotion.FilterId) {
          deferred.resolve(promotion);
        } else {
          const filterUrl = baseUrl + pathToODataApi + `Filters?$filter=FilterId%20eq%20${promotion.FilterId}`;
          execute(filterUrl).then((filters) => {
            let filter = getSafely(['value', 0], filters);
            filter.ClientPromotionFilters = JSON.parse(filter.ClientPromotionFilters);
            promotion.Filter = filter;
            deferred.resolve(promotion)
          }).catch((x) => deferred.reject(x));
        }
      }).catch((x) => deferred.reject(x));

      return deferred.promise;
    }
  }

  function ReportsRepository($http, generalHttpExecutor) {
    var http = $http;

    return {
      generateGraphData: generateGraphData,
      exportToExcel: exportToExcel,
      unifiedStructuredFilesReport: unifiedStructuredFilesReport,
      unifiedStructuredFiles: unifiedStructuredFiles,
      print: print,
      send: send,
      download: download,
      downloadHashavshevet: downloadHashavshevet,
      sendHashavshevet: sendHashavshevet,
      plain: plain,
      total: total,
      exportProducts
    };

    function unifiedStructuredFilesReport(from, to) {
      var url = baseUrl + pathToApi + "IRSReports/UnifiedStructureFilesReport"
        + "?from=" + from + "&to=" + to;

      return generalHttpExecutor.execute(url);
    }

    function print(from, to, reportTypes, showActionDetails) {
      var url = baseUrl + pathToApi + "Reports/PrintReports"
        + "?start=" + from + "&end=" + to
        + "&types=" + reportTypes + "&showActionDetails=" + showActionDetails;

      return generalHttpExecutor.execute(url);
    }

    function send(from, to, reportTypes, showActionDetails, email) {
      var url = baseUrl + pathToApi + "Reports/SendReports"
        + "?start=" + from + "&end=" + to
        + "&types=" + reportTypes + "&showActionDetails=" + showActionDetails
        + "&email=" + email;

      return generalHttpExecutor.execute(url);
    }

    function download(from, to, reportTypes, showActionDetails) {
      var url = baseUrl + pathToApi + "Reports/DownloadReports"
        + "?start=" + from + "&end=" + to
        + "&types=" + reportTypes + "&showActionDetails=" + showActionDetails;

      window.location = url;
    }

    function downloadHashavshevet(from, to, method) {
      var url = baseUrl + pathToApi + "Reports/DownloadHashavshevet"
        + "?start=" + from + "&end=" + to
        + "&method=" + method;

      window.location = url;
    }

    function sendHashavshevet(from, to, method, email) {
      var url = baseUrl + pathToApi + "Reports/SendHashavshevet"
        + "?start=" + from + "&end=" + to
        + "&method=" + method
        + "&email=" + email;

      return generalHttpExecutor.execute(url);
    }


    function plain(from, to, type) {
      var url = baseUrl + pathToApi + "Reports/" + type + "PlainNumbers"
        + "?start=" + from + "&end=" + to;
      return generalHttpExecutor.execute(url);
    }

    function total(from, to) {
      var url = baseUrl + pathToApi + "Reports/Total"
        + "?start=" + from + "&end=" + to;
      return generalHttpExecutor.execute(url);
    }


    function exportProducts(query) {
      var url = baseUrl + pathToApi + "Reports/ExportProductsAndServices" + query;
      window.location = url;
    }


    function unifiedStructuredFiles(id) {
      var url = baseUrl + pathToApi + "IRSReports/UnifiedStructureFiles?id=" + id;
      window.location = url;
    }

    function exportToExcel(dateResolver, reportType, dictionary, callback) {
      var dateRange = dateResolver();
      var from = moment(dateRange.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
      var to = moment(dateRange.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
      var url = baseUrl + pathToApi + "Reports/" + reportType + "DataFile" + "?start=" + from + "&end=" + to + "&dictionary=" + JSON.stringify(dictionary);
      window.location = url;
      if (callback) {
        callback();
      }
    }

    function generateGraphData(dateResolver, graphTypeResolver, includeData, reportType) {
      return new function () {
        var that = this;
        var paramsToConcatenate = {};

        this.orderBy = function (orderValue, decending) {
          paramsToConcatenate.orderby = "&$orderby=" + orderValue + (decending ? " desc" : "");
          return that;
        };

        this.top = function (top) {
          paramsToConcatenate.top = "&$top=" + top;
          return that;
        };

        this.skip = function (skip) {
          paramsToConcatenate.skip = "&$skip=" + skip;
          return that;
        };

        this.filter = function (filterValues) {
          paramsToConcatenate.filter = "&selectedIds=" + JSON.stringify(filterValues); // the desired string in the URL.
          return that;
        };

        this.get = function () {
          var dateRange = angular.extend({}, dateResolver());
          dateRange.from = moment(dateRange.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
          dateRange.to = moment(dateRange.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
          var url = baseUrl + pathToApi + "Reports/" + reportType + "Data" + "?start=" + dateRange.from + "&end=" + dateRange.to;

          if (!includeData) {
            url = baseUrl + pathToApi + "Reports/" + reportType
              + graphTypeResolver() + "Graph" + "?start=" + dateRange.from + "&end=" + dateRange.to;
          }

          angular.forEach(paramsToConcatenate, function (param) {
            url += param;
          });

          return execute(url,
            function (x) {
              return [x.Name, x.Value, x.Value2, x.Value3];
            }, includeData
          );
        };
      };
    }

    function execute(url, resolveValueFunc, isDataMode) {
      return $http({
        url: url,
        dataType: 'json'
      }).then(function (response) {
        var data = response.data;
        if (isDataMode) {
          return data;
        } else {
          return {
            metadata: asArray(data.metadata).map(formatDate),
            values: objectToArrayCouple(data.results, resolveValueFunc),
            valueNames: data.ValueNames,
            total: data.Total,
            byCategory: data.GroupedByCategory
          }
        }
      }).catch(function (err) {
        return err;
      });
    }

    function objectToArrayCouple(result, resolveValueFunc) {
      return asArray(result).map(function (x) {
        return {
          name: x.name,
          data: toArray(x.data, resolveValueFunc)
        };
      });
    }

    function toArray(arr, resolveValueFunc) {
      var arrayToReturn = [];

      angular.forEach(arr, function (obj) {
        arrayToReturn.push(resolveValueFunc(obj));
      });

      return arrayToReturn;
    }

    function formatDate(val) {
      return val;
    }
  }

  function EntitiesLazyRepository($http, $cache, httpExecuter) {
    return {
      productOrServiceByCategory: productOrServiceByCategory,
      externalVouchersByVendor: externalVouchersByVendor,
      productCategories: productCategories,
      serviceCategories: serviceCategories,
      tags: tags,
      memberships: memberships,
      arrivalsources: arrivalsources,
      leadSubStatus: leadSubStatus,
      leadCategories: leadCategories,
      employees: employees,
      manufacturers: manufacturers,
      expenseCategories: expenseCategories,
      create: create,
      update: update,
      updateCategory: updateCategory,
      updateBatch: updateBatch,
      updateInventory: updateInventory
    };

    function tags(entityType) {
      return new function () {
        var that = this;
        this.filterId = function (id) {
          return that;
        };

        this.get = function () {
          var url = baseUrl + pathToODataApi + 'Tags' + '?$filter=EntityType eq \'' + entityType + "'";
          return execute(url, function (x) {
            return {
              name: x.Name,
              value: x.TagId
            };
          });
        };
      };
    }

    function productCategories() {
      return httpExecuter.execute(`${baseUrl}${pathToODataApi}ProductCategories`, true)
        .then(({value}) => generateCategoriesMap(value, 'ProductCategoryId', 'ProductCategoryName'));
    }

    function serviceCategories() {
      return httpExecuter.execute(`${baseUrl}${pathToODataApi}ServiceCategories`, true).then(({value}) => generateCategoriesMap(value, 'ServiceCategoryId', 'ServiceCategoryName'));
    }

    function generateCategoriesMap(categories, idField, nameField) {
      const globalMap = categories.reduce((acc, curr) => ({...acc, [curr[idField]]: curr}), {});
      categories.forEach((category) => {
        category.name = category[nameField];
        category.value = category[idField];
        category.notes = Math.floor(Math.random() * 10000); /// TODO: Daniel remove.
        if (category.Path) {
          const parent = globalMap[category.Path.replace(/\'/gi, '').split(',').pop()];
          if (parent) {
            parent.children = parent.children || [];
            parent.children.push(category);
            category.parent = parent;
          }
        }
      });

      return Object.values(globalMap).filter((x) => !x.Path);
    }

    function memberships() {
      return new function () {
        var that = this;
        this.filterId = function (id) {
          return that;
        };

        this.get = function () {
          var url = baseUrl + pathToODataApi + 'MembershipDiscountMetadatas';
          return execute(url, function (x) {
            return {
              name: x.Name,
              value: x.MembershipDiscountMetadataId
            };
          });
        };
      };
    }

    function arrivalsources() {
      return new function () {
        var that = this;
        this.filterId = function (id) {
          return that;
        };

        this.get = function () {
          var url = baseUrl + pathToODataApi + 'ArrivalSources';
          return execute(url, function (x) {
            return {
              name: x.ArrivalSourceName,
              value: x.ArrivalSourceId,
              slug: x.ArrivalSourceId.toString()
            };
          });
        };
      };
    }

    function leadSubStatus() {
      return new function () {
        var that = this;
        this.filterId = function (id) {
          return that;
        };

        this.get = function () {
          var url = baseUrl + pathToODataApi + 'LeadSubStatus';
          return execute(url, function (x) {
            return {
              name: x.LeadSubStatusName,
              value: x.LeadSubStatusId,
              slug: x.LeadSubStatusId.toString()
            };
          });
        };
      };
    }

    function leadCategories() {
      return new function () {
        var that = this;
        this.filterId = function (id) {
          return that;
        };

        this.get = function () {
          var url = baseUrl + pathToODataApi + 'LeadCategories';
          return execute(url, function (x) {
            return {
              name: x.LeadCategoryName,
              value: x.LeadCategoryId,
              slug: x.LeadCategoryId.toString()
            };
          });
        };
      };
    }

    function employees() {
      return new function () {
        var that = this;
        this.filterId = function (id) {
          return that;
        };

        this.get = function () {
          var url = baseUrl + pathToODataApi + 'Employees';
          return execute(url, function (x) {
            return {
              name: x.FirstName + ' ' + x.LastName,
              value: x.EmployeeId,
              slug: x.EmployeeId.toString()
            };
          });
        };
      };
    }

    function manufacturers() {
      return new function () {
        var that = this;
        this.filterId = function (id) {
          return that;
        };

        this.get = function () {
          var url = baseUrl + pathToODataApi + 'Manufacturers';
          return execute(url, function (x) {
            return {
              name: x.ManufacturerName,
              value: x.ManufacturerId
            };
          });
        };
      };
    }

    function expenseCategories() {
      return new function () {
        var that = this;
        this.filterId = function (id) {
          return that;
        };

        this.get = function () {
          var url = baseUrl + pathToODataApi + 'ExpensesCategories';
          return execute(url, function (x) {
            return {
              name: x.ExpensesCategoryName,
              value: x.ExpensesCategoryId
            };
          });
        };
      };
    }

    function externalVouchersByVendor() {
      var url = baseUrl + pathToODataApi + 'ExternalVoucherMetadatas?$expand=ServiceMetadatas,ExternalVoucherVendor&$select=ExternalVoucherMetadataId,ExternalVoucherName,Price,ExternalVoucherVendorId,ExternalVoucherVendor/ExternalVoucherVendorName,ServiceMetadatas/ServiceMetadataId,ServiceMetadatas/ServiceName';
      var vendorIdToVendor = {};
      return execute(url, function (externalVoucherMetadata) {
        var vendorId = externalVoucherMetadata.ExternalVoucherVendorId;
        vendorIdToVendor[vendorId] = vendorIdToVendor[vendorId] || [];
        vendorIdToVendor[vendorId].push(externalVoucherMetadata);

        return externalVoucherMetadata;
      }).then(function (results) {
        return vendorIdToVendor;
      })

    }

    function productOrServiceByCategory(includeConcreteProducts, defaultItem) {
      return new function () {
        var that = this;
        var paramsToConcatenate = {};

        this.defaultItem = defaultItem;

        this.filterId = function (id) {
          paramsToConcatenate.filter = "&$filter=" + that.entityCategoryId + "%20eq%20" + id;
          return that;
        };

        this.entity = function (entity) {
          this.entityType = entity === "product" ? "Product" : entity === "service" ? "Service" : null;
          if (this.entityType == null) {
            throw "This entity is not yet supported";
          }

          this.entityCategories = this.entityType + "Categories";
          this.entityCategoryId = this.entityType + "CategoryId";
          this.entityCategoryName = this.entityType + "CategoryName";
          this.entityCategoryLabel = entity === "service" ? "ServiceName" : this.entityType + "Label";
          this.entityConcrete = this.entityType + "Metadatas";
          this.entityConcreteId = this.entityType + "MetadataId";

          return this;
        };

        /**
         * This passes delegate to the promise whenever a request is being sent.
         * */
        this.delegate = function (delegate) {
          this.delegatePointer = delegate;
          return this;
        };

        this.get = function (forceReloadCache) {
          if (!angular.isDefined(this.entityType)) {
            throw "Must select entity first.";
          }

          var url = baseUrl + pathToODataApi + that.entityCategories + "?$expand=" + that.entityCategories +
            (includeConcreteProducts ? ("," + that.entityConcrete) : "");
          angular.forEach(paramsToConcatenate, function (param) {
            url += param;
          });

          if (!paramsToConcatenate.filter) {
            url += "&$filter=Path%20eq%20null%20or%20Path%20eq%20''"; // include only 'father' categories - categories
            // that has no father.
          }

          var promiseToReturn = execute.call(that, url,
            function (x) {
              var toReturn = {};
              // is it category?
              if (x.hasOwnProperty(that.entityCategoryId)) {
                toReturn = generateCategory(x);
              } else { // it is a product.
                toReturn = generateClientProduct(x);
              }

              return toReturn;
            }
            , forceReloadCache);

          if (that.delegatePointer) {
            promiseToReturn.then(function () {
              var args = [];
              args.push.apply(args, arguments);
              setTimeout(function () {
                that.delegatePointer.apply(that.delegatePointer, args);
              });

              return args;
            })
          }

          return promiseToReturn;

          function generateCategory(serverCategory, current) {
            var toReturn = current;
            if (!current) {
              toReturn = {};
              toReturn.name = serverCategory[that.entityCategoryName];
              toReturn.categoryValue = serverCategory[that.entityCategoryId];
              toReturn.value = [];
              toReturn.wasExpanded = false;
              toReturn.valueType = "Category";
            } else {
              if (angular.isDefined(current.isSelected) && current.isSelected) {
                angular.forEach(serverCategory.value, function (newCategory) {
                  newCategory.isSelected = true;
                });
              }

              toReturn.value.pushAll(serverCategory.value);
            }

            if ((angular.isDefined(serverCategory[that.entityCategories]) && serverCategory[that.entityCategories].length > 0) ||
              (angular.isDefined(serverCategory[that.entityConcrete]) && serverCategory[that.entityConcrete].length > 0)) {

              if (angular.isDefined(serverCategory[that.entityConcrete]) && serverCategory[that.entityConcrete].length > 0) {
                angular.forEach(serverCategory[that.entityConcrete], function (product) {
                  if (!product.IsDeactiveted) {
                    toReturn.value.push(generateClientProduct(product));
                  }
                });
              }

              if (serverCategory[that.entityCategories].length > 0) {
                angular.forEach(serverCategory[that.entityCategories], function (category) {
                  var internalCategory = generateCategory(category);
                  internalCategory.expand = function (wrappedFunc) {
                    if (!internalCategory.wasExpanded) {
                      internalCategory.wasExpanded = true;
                      var subRepository = $.extend({}, that);
                      subRepository.filterId(category[that.entityCategoryId]);
                      subRepository.get().then(function (data) {
                        wrappedFunc(function () {
                          generateCategory(data[0], internalCategory);
                        });
                      });
                    }
                  };


                  toReturn.value.push(internalCategory);
                });


              }
            }

            return toReturn;
          }

          function generateClientProduct(serverProduct) {
            return {
              name: serverProduct[that.entityCategoryLabel],
              value: serverProduct[that.entityConcreteId]
            };
          }
        };
      };
    }

    function execute(url, resolveValueFunc, forceReloadCache) {
      var deferred = $q.defer();

      $http({
        url: url,
        method: "GET"
      }).then(function ({data}) {
        deferred.resolve(resolveValueFunc ? data.value.map(resolveValueFunc) : data.value);
      }).catch(function (err) {
        deferred.reject(err);
      });


      return deferred.promise;
    }

    function create(productOrService) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "ProductOrServiceMetadataCreator/" + productOrService.EntityType;

      $http({
        url: url,
        dataType: 'json',
        data: JSON.stringify(productOrService),
        method: "POST"
      }).then(function ({data}) {
        productsPickerRepository.clearCache();
        data.ProductOrService.EntityType = "ProductOrService";
        data.ProductOrService.ConcreteType = productOrService.EntityType;
        deferred.resolve(data);
        $cache.removeByName(productOrService.EntityType);
        $cache.removeByName("Manufacturer");
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    function update(productOrService) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "ProductOrServiceMetadataCreator/Update" + productOrService.ConcreteType;
      var concreteType = productOrService.ConcreteType;
      delete productOrService.ConcreteType;

      $http({
        url: url,
        method: "PUT",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(productOrService)
      }).then(function ({data}) {
        productsPickerRepository.clearCache();
        deferred.resolve(data);
        $cache.removeByName("Manufacturer");
        $cache.removeByName(productOrService.EntityType);
        $cache.removeByName(concreteType);
      }).catch(function (jqXHR, textStatus, errorThrown) {
        deferred.reject(errorThrown);
      });

      return deferred.promise;
    }

    function updateCategory(productOrServiceCategory) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "ProductOrServiceMetadataCreator/UpdateCategory";
      $http({
        url: url,
        method: "PUT",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(productOrServiceCategory)
      }).then(function ({data}) {
      }).catch(function (jqXHR, textStatus, errorThrown) {
        deferred.reject(errorThrown);
      });

      return deferred.promise;
    }

    function updateBatch(products) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "ProductOrServiceMetadataCreator/UpdateBatch";
      productsPickerRepository.clearCache();

      $http({
        url: url,
        method: "PUT",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(products)
      }).then(function ({data}) {
        deferred.resolve(data);
        $cache.removeByName("Manufacturer");
        $cache.removeByName("ServiceMetadataId");
        $cache.removeByName("ProductMetadataId");

      }).catch(function (jqXHR, textStatus, errorThrown) {
        deferred.reject(errorThrown);
      });

      return deferred.promise;
    }

    function updateInventory(dataToSend) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "ProductOrServiceMetadataCreator/UpdateInventory";
      productsPickerRepository.clearCache();

      $http({
        url: url,
        method: "POST",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(dataToSend)
      }).then(function ({data}) {
        deferred.resolve(data);
        $cache.removeByName("ProductMetadataId");
        productsPickerRepository.clearCache();
      }).catch(function (jqXHR, textStatus, errorThrown) {
        deferred.reject(errorThrown);
      });

      return deferred.promise;
    }
  }


  function CategoriesEditorRepository($http, $cache, httpExecuter) {
    return {
      get: get,
      update: update,
      rename: rename,
      merge: merge,
      restore: restore,
      remove: remove
      //productCategories,serviceCategories,manufacturers,leadsTags,leadSubStatus,customersTags,arrivalSources,expenseCategories,externalVouchersVendors,
    };

    function get(type, searchText) {
      const url = `${baseUrl}${pathToApi}CategoriesEditor?type=${type}&search=${typeof searchText === 'string' && searchText || ''}`;
      return httpExecuter.execute(url, true).then((result) => generateCategoriesMap(result));
    }

    function rename(type, id, name) {
      const url = `${baseUrl}${pathToApi}CategoriesEditor/Rename?type=${type}&id=${id}&name=${name}`;
      return httpExecuter.put(url).then((result) => generateCategoriesMap(result));
    }

    function merge(type, ids) {
      const url = `${baseUrl}${pathToApi}CategoriesEditor/Merge?type=${type}&ids=${JSON.stringify(ids)}`;
      return httpExecuter.put(url).then((result) => generateCategoriesMap(result));
    }

    function restore(type, ids) {
      const url = `${baseUrl}${pathToApi}CategoriesEditor/Restore?type=${type}&ids=${JSON.stringify(ids)}`;
      return httpExecuter.put(url).then((result) => generateCategoriesMap(result));
    }

    function remove(type, ids) {
      const url = `${baseUrl}${pathToApi}CategoriesEditor/Delete?type=${type}&ids=${JSON.stringify(ids)}`;
      return httpExecuter.delete(url).then((result) => generateCategoriesMap(result));
    }

    function generateCategoriesMap(categories) {
      const globalMap = categories.reduce((acc, curr) => ({...acc, [curr.Id]: curr}), {});
      categories.forEach((category) => {
        category.name = category.Name;
        category.value = category.Id;
        category.notes = (category.ActiveCount ? (category.ActiveCount) : "");
        if (category.Path) {
          const parent = globalMap[category.Path.replace(/\'/gi, '').split(',').pop()];
          if (parent) {
            parent.children = parent.children || [];
            parent.children.push(category);
            category.parent = parent;
          }
        }
      });

      return Object.values(globalMap).filter((x) => !x.Path);
    }

    function execute(url) {
      var deferred = $q.defer();

      $http({
        url: url,
        method: "GET"
      }).then(function (result) {
        deferred.resolve(result.data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    function update(productOrService) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "ProductOrServiceMetadataCreator/Update" + productOrService.ConcreteType;
      var concreteType = productOrService.ConcreteType;
      delete productOrService.ConcreteType;

      $http({
        url: url,
        method: "PUT",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(productOrService)
      }).then(function ({data}) {
        deferred.resolve(data);
        $cache.removeByName("Manufacturer");
        $cache.removeByName(productOrService.EntityType);
        $cache.removeByName(concreteType);
        productsPickerRepository.clearCache();
      }).catch(function (jqXHR, textStatus, errorThrown) {
        deferred.reject(errorThrown);
      });

      return deferred.promise;
    }
  }


  function ConfigurationsRepository($http, httpExecuter) {
    return {
      get: get,
      update: update,
      getDefaultEmployee: getDefaultEmployee,
      authorize: authorize,
      getPersonalConfigurations: getPersonalConfigurations,
      updatePersonalConfigurations: updatePersonalConfigurations,
      getUserData: getUserData,
      videoWatched: videoWatched,
      cashRegisterFavorites: cashRegisterFavorites,
      reportsLayout: reportsLayout,
      mailForZByDate: mailForZByDate,
      loggedInUser: loggedInUser,
      setPreset: setPreset,
      deletePreset: deletePreset,
      setEmployeeForSession: setEmployeeForSession,
      updateFacebookConnect,
      clearFacebookConnect,
      getFacebookPages,
      registerFacebookPages,
      deregisterFacebookPages,
      updateWhatsappDetails,
      loadBotConfigurations,
      patchBotConfigurations,
      patchExtension,
      preventCallerId,
      updateDefaultEmployee
    };

    function get(/*readonly*/) {
      var url = baseUrl + pathToApi + "Configuration";
      // Not is use.
      // if (readonly) {
      //   url += "/ReadOnly";
      // }

      return executeRequest(url);
    }

    function update(configurations) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "Configuration";

      $http({
        url: url,
        method: "PUT",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(configurations)
      }).then(function (data, textStatus, jqXHR) {
        deferred.resolve(data);
      }).catch(function (jqXHR, textStatus, errorThrown) {
        deferred.reject(errorThrown);
      });

      return deferred.promise;
    }

    function getDefaultEmployee() {
      var url = baseUrl + pathToApi + "Configuration/DefaultEmployee";
      return executeRequest(url, true);
    }

    function authorize(password) {
      var url = baseUrl + pathToApi + "Configuration/AuthorizeUser";
      return httpExecuter.post(url, password);
    }

    function getPersonalConfigurations() {
      var url = baseUrl + pathToApi + "Configuration/Mine";
      return httpExecuter.execute(url);
    }

    function updatePersonalConfigurations(settings) {
      const url = `${window.loginUrl.replace(/\/login.*/, '')}/${pathToApi}accounts/my/configurations`
      return httpExecuter.put(url, {configurations: settings}, true, true);
    }

    function getUserData() {
      var url = baseUrl + pathToApi + "configuration/userdata";
      return httpExecuter.execute(url);
    }

    function videoWatched(id) {
      var url = baseUrl + pathToApi + "Configuration/VideoWatched?id=" + id;
      return httpExecuter.put(url);
    }

    function cashRegisterFavorites(value) {
      var url = baseUrl + pathToApi + "Configuration/CashRegisterConfiguration";
      return httpExecuter.put(url, value);
    }

    function reportsLayout(value) {
      var url = baseUrl + pathToApi + "Configuration/ReportsLayout";
      return httpExecuter.put(url, value);
    }

    function mailForZByDate() {
      var url = baseUrl + pathToApi + "Configuration/MailForZByDate";
      return executeRequest(url, false);
    }

    function loggedInUser() {
      const url = window.loginUrl.replace(/\/login.*/, '') + '/api/users/my';
      return performAuthRequest(url);
    }

    function setEmployeeForSession(employeeId) {
      const url = window.loginUrl.replace(/\/login.*/, '') + `/api/login/set-employee/${employeeId}`;
      return performAuthRequest(url);
    }

    function updateFacebookConnect(data) {
      const url = window.loginUrl.replace(/\/login.*/, '') + `/api/contexts/context-settings/whatsapp/register`
      return httpExecuter.post(url, data, null, true);
    }

    function registerFacebookPages(accessToken) {
      const url = window.loginUrl.replace(/\/login.*/, '') + `/api/contexts/facebook-leads`
      return httpExecuter.post(url, {accessToken}, null, true);
    }

    function getFacebookPages() {
      const url = window.loginUrl.replace(/\/login.*/, '') + `/api/contexts/facebook-leads`
      return httpExecuter.get(url, true, true);
    }

    function deregisterFacebookPages(optionalAccessToken) {
      const url = window.loginUrl.replace(/\/login.*/, '') + `/api/contexts/facebook-leads`
      return httpExecuter.delete(url, {accessToken: optionalAccessToken || null}, true);
    }


    function updateWhatsappDetails(data) {
      const url = window.loginUrl.replace(/\/login.*/, '') + `/api/contexts/context-settings/whatsapp`
      return httpExecuter.post(url, data, null, true);
    }

    function clearFacebookConnect() {
      const url = window.loginUrl.replace(/\/login.*/, '') + `/api/contexts/context-settings/facebook`
      return httpExecuter.delete(url, null, true);
    }

    function loadBotConfigurations() {
      const url = window.conversationsURI + '/api/context-settings?includeDefaults=true'
      return performAuthRequest(url);

    }

    function patchBotConfigurations(body) {
      const url = window.conversationsURI + '/api/context-settings';
      return httpExecuter.patch(url, body, true, true);
    }

    function patchExtension(extension) {
      const url = window.loginUrl.replace(/\/login.*/, '') + '/api/users/my/extension';
      return httpExecuter.patch(url, {extension}, true, true);
    }

    function preventCallerId(prevent) {
      var url = baseUrl + pathToApi + "Configuration/PreventCallerId?prevent=" + prevent;
      return httpExecuter.put(url);
    }

    function updateDefaultEmployee(employeeId) {
      var url = baseUrl + pathToApi + "Configuration/SetDefaultEmployee?employeeId=" + employeeId;
      return httpExecuter.put(url);
    }

    function setPreset(view, preset, saveForAll) {
      const url = window.loginUrl.replace(/\/login.*/, '') + '/api/accounts/my/presets';
      return httpExecuter.put(url, {view, preset, saveForAll}, true, true);
    }

    function deletePreset(uid) {
      const url = window.loginUrl.replace(/\/login.*/, '') + '/api/accounts/my/presets/' + uid;
      return httpExecuter.delete(url, true, true);
    }

    function performAuthRequest(url) {
      var deferred = $q.defer();
      // var url = window.loginUrl.replace(/\/login.*/, '') + `/api/login/set-employee/${employeeId}`;
      var req = new XMLHttpRequest();
      req.open('GET', url, true); // force XMLHttpRequest2
      req.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
      req.setRequestHeader('Accept', 'application/json');
      req.withCredentials = true; // pass along cookies
      req.onload = function () {
        var json;
        try {
          json = JSON.parse(req.responseText);
          if (req.status !== 200) {
            return deferred.reject(json);
          }

          deferred.resolve(json);
        } catch (error) {
          return deferred.reject(error);
        }
      };

      req.onerror = deferred.reject;
      req.send();

      return deferred.promise;
    }

    function executeRequest(url) {
      return httpExecuter.execute(url)
        .then(function (data) {
          var toReturn = null;
          if (Object.prototype.toString.call(data) === '[object Array]') {
            toReturn = data[0];
          } else {
            toReturn = data;
          }

          return toReturn;
        }).catch(function (err) {
          return err;
        });
    }

  }

  function FeedbackRepository($http) {

    return {
      submit: submit
    };

    function submit(feedback) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "Feedback";

      $http({
        url: url,
        method: "POST",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(feedback)
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (jqXHR, textStatus, errorThrown) {
        deferred.reject(errorThrown);
      });

      return deferred.promise;
    }
  }

  function CustomersFeedbackRepository($http, httpExecutor) {

    return {
      sendFeedback: sendFeedback,
      feedbacksAverageGrade: feedbacksAverageGrade,
      hideFeedbackDialog: hideFeedbackDialog
    };

    function sendFeedback(feedback) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "CustomersFeedback";

      $http({
        url: url,
        method: "POST",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(feedback)
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (jqXHR, textStatus, errorThrown) {
        deferred.reject(errorThrown);
      });

      return deferred.promise;
    }

    function hideFeedbackDialog(value) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "CustomersFeedback/HideFeedbackDialog?value=" + value;

      $http({
        url: url,
        method: "PUT",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: null
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (jqXHR, textStatus, errorThrown) {
        deferred.reject(errorThrown);
      });

      return deferred.promise;
    }

    function feedbacksAverageGrade() {
      return httpExecutor.execute(`${reviewsURI}/api/feedbacks/average-grade`, true, true)
    }
  }


  function IsraelInvoiceRepository($http) {
    return {
      getIsraelInvoiceToken,
      connectIsraelInvoice,
      deleteIsraelInvoice,
      reallocateIsraelInvoiceNumber,
      getAuthToken
    };

    function getIsraelInvoiceToken() {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "IsraelInvoice";

      $http({
        url: url,
        method: "GET",
        cache: false
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    function reallocateIsraelInvoiceNumber(invoiceId) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "IsraelInvoice/GetAllocationNumberForInvoice?invoiceId=" + invoiceId;

      $http({
        url: url,
        method: "GET",
        cache: false
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    function deleteIsraelInvoice() {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "IsraelInvoice";

      $http({
        url: url,
        method: "DELETE"
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    function postToServer(data) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "IsraelInvoice/AccessToken";

      $http({
        url: url,
        dataType: 'json',
        data: JSON.stringify(data),
        method: "POST"
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }


    function connectIsraelInvoice() {
      var urlParams = `?response_type=code&client_id=${israelInvoiceAppId}&scope=scope&redirect_uri=${israelInvoiceRedirectUrl}`;
      window.location.href = israelInvoiceAuthCodeUrl + urlParams;
    }

    function getAuthToken(authCode) {
      var deferred = $q.defer();
      var grantType = "authorization_code";
      var scope = "scope";

      $http({
        method: 'POST',
        url: israelInvoiceAuthTokenUrl,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic ' + btoa(israelInvoiceAppId + ':' + israelInvoiceAppSecret)
        },
        data: 'grant_type=' + grantType +
          '&code=' + authCode +
          '&redirect_uri=' + israelInvoiceRedirectUrl +
          '&scope=scope' + scope
      })
        .then(function (response) {
          postToServer(response.data).then(function (data) {
            deferred.resolve(data);
          }).catch(function (err) {
            deferred.reject(err);
          });
        })
        .catch(function (error) {
          console.log("get token error: " + JSON.stringify(error));
          deferred.reject(error);
        });

      return deferred.promise;
    }
  }


  function CashRegisterRepository($http, custom$HttpCache, httpExecuter) {
    return {
      zReport: zReport,
      xReport: xReport,
      xReportData: xReportData,
      invoiceReceipt: invoiceReceipt,
      submitInvoice: submitInvoice,
      submitTaxInvoice: submitTaxInvoice,
      cancelInvoice: cancelInvoice,
      customCancelInvoice: customCancelInvoice,
      cancelLostPayment: cancelLostPayment,
      cancelReceipt: cancelReceipt,
      invoiceCreditPayments: invoiceCreditPayments,
      lostCreditPayments: lostCreditPayments,
      deleteLostCancelledPayments: deleteLostCancelledPayments,
      sendByMail: sendByMail,
      isZOverlapping: isZOverlapping,
      customerServicesFromMeetings: customerServicesFromMeetings,
      customerInvoiceHistory: customerInvoiceHistory,
      createSubscriptionUsages: createSubscriptionUsages,
      productOrServices: productOrServices,
      subscriptionUnclosedUsages: subscriptionUnclosedUsages,
      updateInventory: updateInventory,
      taxInvoiceResult: taxInvoiceResult,
      invoiceResult: invoiceResult,
      receiptResult: receiptResult,
      sendInvoice: sendInvoice,
      taxInvoiceDebts: taxInvoiceDebts,
      generalTaxInvoiceDebts: generalTaxInvoiceDebts,
      invoiceDemand: invoiceDemand,
      creditVoucher: creditVoucher,
      produceTaxInvoice,
      validateInvoice,
      validateDiscountCode
    };

    function zReport() {
      var url = baseUrl + pathToApi + "CashRegister/ZReport";
      return executeRequest(url, "GET");
    }

    function xReport(date) {
      var url = baseUrl + pathToApi + "CashRegister/XReport" + (date ? "?date=" + date : "");
      return executeRequest(url, "GET");
    }

    function xReportData(employeeId) {
      var url = baseUrl + pathToApi + "CashRegister/XReportData?employeeId=" + employeeId;
      return executeRequest(url, "GET");
    }

    function invoiceReceipt(id) {
      var url = baseUrl + pathToApi + "CashRegister/InvoiceReceipt?id=" + id;
      return executeRequest(url, "GET");
    }

    function submitInvoice(invoice) {
      var url = baseUrl + pathToApi + "CashRegister/Invoice";
      return executeRequest(url, "POST", invoice);
    }

    function submitTaxInvoice(invoice) {
      var url = baseUrl + pathToApi + "CashRegister/TaxInvoice";
      return executeRequest(url, "POST", invoice);
    }

    function taxInvoiceResult(taxInvoiceId) {
      var url = baseUrl + pathToApi + "CashRegister/PartialTaxInvoice?id=" + taxInvoiceId;
      return executeRequest(url, "GET");
    }

    function customerServicesFromMeetings(customerId, meetingId, date) {
      const query = {customerId, meetingId, date};
      const url = baseUrl + pathToApi + 'CashRegisterHelper/CustomerServicesFromMeetings?' + Object.keys(query).map((key) => `${key}=${query[key] || 'null'}`).join('&');
      return executeRequest(url, "GET").then((res) => getSafely(['results', 0], res));
    }

    function sendByMail(invoiceData) {
      var url = baseUrl + pathToApi + "CashRegister/MailInvoice";
      return executeRequest(url, "POST", invoiceData);
    }

    function cancelInvoice(id, creditRefundsIds) {
      var url = baseUrl + pathToApi + "CashRegister/CancelInvoice?id=" + id;
      return executeRequest(url, "POST", creditRefundsIds);
    }

    function customCancelInvoice(invoiceId, body) {
      var url = baseUrl + pathToApi + 'CashRegister/CancelInvoiceNew?id=' + invoiceId;
      return executeRequest(url, 'POST', body);
    }

    function cancelLostPayment(lostPaymentId, body) {
      var url = baseUrl + pathToApi + 'CashRegister/CancelLostPayment?id=' + lostPaymentId;
      return executeRequest(url, 'POST', body);
    }

    function cancelReceipt(id, creditRefundsIds) {
      var url = baseUrl + pathToApi + "CashRegister/CancelReceipt?id=" + id;
      return executeRequest(url, "POST", creditRefundsIds);
    }

    function invoiceCreditPayments(id) {
      var url = baseUrl + pathToApi + "CashRegister/InvoiceCreditPayments?id=" + id;
      return executeRequest(url, "GET");
    }

    function lostCreditPayments() {
      const url = `${pciURI}/${pathToApi}transactions/lost`;
      return httpExecuter.execute(url, true, true);
    }

    function deleteLostCancelledPayments(paymentIds) {
      var url = baseUrl + pathToApi + "Credit/DeleteCancelledPayments";
      return executeRequest(url, "POST", paymentIds);
    }

    function isZOverlapping() {
      var url = baseUrl + pathToApi + "CashRegister/IsZOverlaping";
      return executeRequest(url, "GET");
    }

    function customerInvoiceHistory(customerId) {
      var url = baseUrl + pathToApi + 'CashRegisterHelper/CustomerPurchaseHistory?customerid=' + customerId;
      return executeRequest(url, "GET");
    }

    function createSubscriptionUsages(usagesToCreateInvoice) {
      var url = baseUrl + pathToApi + 'CashRegister/CreateSubscriptionUsages';
      return executeRequest(url, "POST", usagesToCreateInvoice);
    }

    function updateInventory(productMetadataId, updatedInventory, currentInventory) {
      var url = baseUrl + pathToApi + 'CashRegisterHelper/UpdateInventory?productMetadataId=' + productMetadataId + '&updatedInventory=' + updatedInventory + '&inventoryBeforeUpdate=' + currentInventory;
      return executeRequest(url, "PUT");
    }

    function taxInvoiceDebts(customerId, showClosed = false) {
      var url = baseUrl + pathToApi + 'CashRegisterHelper/CustomerTaxInvoiceDebts?customerId=' + customerId + '&showClosed=' + showClosed;
      return executeRequest(url, "GET");
    }

    function generalTaxInvoiceDebts(excludeCustomerId, showClosed = false) {
      const query = new Query(() => {
        return `${baseUrl}${pathToApi}CashRegisterHelper/TaxInvoiceDebts?excludeCustomerId=${excludeCustomerId}&showClosed=${showClosed}`;
      });

      query.get = () => {
        return executeRequest(query.getQuery(), "GET")
      };

      return query;
    }

    function productOrServices(top, skip) {
      var url = baseUrl + pathToApi + 'CashRegisterHelper/ProductOrServices?$orderby=ConcreteType%20desc' + (angular.isDefined(top) || angular.isDefined(skip) ? '&' : '');
      if (angular.isDefined(top) && !!top) {
        url += '$top=' + top;
        if (angular.isDefined(skip)) {
          url += '&';
        }
      }

      if (angular.isDefined(skip) && !!skip) {
        url += '$skip=' + skip;
      }


      return executeRequest(url, "GET");
    }

    function subscriptionUnclosedUsages() {
      var url = baseUrl + pathToApi + 'CashRegisterHelper/SubscriptionUnclosedUsages';
      return executeRequest(url, "GET");
    }

    function sendInvoice(data) {
      var url = baseUrl + pathToApi + "DigitalInvoice";
      return executeRequest(url, "POST", data);
    }

    function invoiceResult(invoiceId) {
      var url = baseUrl + pathToApi + 'CashRegister/InvoiceResult?id=' + invoiceId;
      return executeRequest(url, "GET");
    }

    function receiptResult(receiptId) {
      var url = baseUrl + pathToApi + 'CashRegister/ReceiptResult?id=' + receiptId;
      return executeRequest(url, "GET");
    }

    function invoiceDemand(Token, Method) {
      const url = baseUrl + pathToApi + 'DigitalInvoice/InvoiceDocumentDemand';
      return executeRequest(url, 'POST', {Token, Method});
    }

    function creditVoucher(paymentId) {
      const url = baseUrl + pathToApi + `CashRegister/CreditReceipt?paymentId=${paymentId}&source=true`;
      return executeRequest(url, "GET");
    }

    function produceTaxInvoice(taxInvoiceId) {
      const url = baseUrl + pathToApi + 'CashRegister/ProduceTaxInvoice?id=' + taxInvoiceId;
      return executeRequest(url, "POST", {});
    }

    function validateInvoice(invoice) {
      var url = baseUrl + pathToApi + "CashRegister/ValidateInvoice";
      return executeRequest(url, "POST", invoice);
    }

    function validateDiscountCode(codeAndEncrypted) {
      var url = baseUrl + pathToApi + "CashRegister/ValidateDiscountCode";
      return executeRequest(url, "POST", codeAndEncrypted);
    }

    function executeRequest(url, method, toPost, cache) {
      var deferred = $q.defer();

      const toPerform = {
        url: url,
        dataType: 'json',
        method: method,
        data: toPost,
        cache: cache ? custom$HttpCache : false
      };

      $http(toPerform).then(function ({data}) {
        if (Object.prototype.toString.call(data) === '[object Array]' ||
          (data && Object.prototype.toString.call(data.value) === '[object Array]')) {
          data.results = data.value;
        } else {
          data = {results: [data]};
        }

        if (method === 'POST' && angular.isObject(toPost) && toPost.CustomerId) {
          custom$HttpCache.removeByName("CustomerId");
        }

        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });


      return deferred.promise;
    }


  }

  function CashRegisterActivitiesRepository(httpExecuter) {
    return {
      query: query,
      expandInvoice: expandInvoice,
      expandReceipt: expandReceipt
    };

    function query() {
      return new function () {
        var paramsToConcatenate = {};

        this.skip = function (i_skip) {
          paramsToConcatenate.skip = "$skip=" + i_skip;
          return this;
        };

        this.top = function (i_top) {
          paramsToConcatenate.top = "$top=" + i_top;
          return this;
        };

        this.search = function (search) {
          if (search.length > 0) {
            paramsToConcatenate.search = "search=" + search;
          } else {
            delete paramsToConcatenate.search;
          }

          return this;
        };

        this.orderBy = function (orderValue, decending) {
          paramsToConcatenate.orderby = "$orderby=CreatedOn desc";
          return this;
        };

        this.get = function () {
          var url = baseUrl + pathToApi + "CashRegisterActivities";
          if (Object.keys(paramsToConcatenate).length > 0) {
            var index = 0;
            angular.forEach(paramsToConcatenate, function (value) {
              if (index == 0) {
                url += "?";
              } else {
                url += "&";
              }

              url += value;
              index++;
            });
          }

          return httpExecuter.execute(url, true);
        };
      };
    }

    function expandInvoice(invoiceId) {
      const url = baseUrl + pathToApi + "CashRegisterActivities/Invoice/" + invoiceId;
      return httpExecuter.execute(url, true);
    }

    function expandReceipt(receiptId) {
      const url = baseUrl + pathToApi + "CashRegisterActivities/Receipt/" + receiptId;
      return httpExecuter.execute(url, true);
    }

  }

  function DashboardRepository(httpExecutor) {
    return {
      customers: function () {
        return get("Customers");
      },
      products: function () {
        return get("Products");
      },
      promotions: function () {
        return get("Promotions");
      },
      dailyIncomes: function () {
        return get("DailyIncomes");
      },
      meetingsForecast: function () {
        return get("MeetingsForecast");
      },
      audit: function () {
        return get("Audit");
      },
      meetings: function (numOfMeetingsToShow) {
        return get('NextMeetings?noOfMeetings=' + numOfMeetingsToShow);
      }
    };

    function get(type) {
      return httpExecutor.execute(`${baseUrl}${pathToApi}Dashboard/${type}`, true);
    }
  }

  function PriceQuoteRepository($http) {
    return {
      get: get,
      getPriceQuotes: getPriceQuotes,
      exportPriceQuotes: exportPriceQuotes,
      getDocumentToken: getDocumentToken,
      save: save,
      send: send,
      cancel: cancel,
      getNextId: getNextId,
      approve: approve,
      pdf: pdf
    };

    function get(id) {
      return execute(baseUrl + pathToApi + 'PriceQuote?id=' + id, "GET", null);
    }

    function getPriceQuotes(searchText, from, to, employee, state, filterByInvoicesDates) {
      return execute(baseUrl + pathToApi + 'PriceQuote/Report?search=' + (searchText || '') +
        '&from=' + (from || null) +
        '&to=' + (to || null) +
        '&employee=' + (employee || null) +
        '&state=' + (state || null) +
        '&filterByInvoicesDates=' + (filterByInvoicesDates || false)
        , "GET", null);
    }

    function exportPriceQuotes(searchText, from, to, employee, state, filterByInvoicesDates) {
      var url = baseUrl + pathToApi + 'PriceQuote/Export?search=' + (searchText || '') +
        '&from=' + (from || null) +
        '&to=' + (to || null) +
        '&employee=' + (employee || null) +
        '&state=' + (state || null) +
        '&filterByInvoicesDates=' + (filterByInvoicesDates || false);
      window.location = url;
    }

    function getDocumentToken(priceQuoteId) {
      return execute(baseUrl + pathToApi + 'PriceQuote/GetDocumentToken?id=' + priceQuoteId
        , "GET", null);
    }

    function getNextId() {
      return execute(baseUrl + pathToApi + 'PriceQuote/GetNextId', "GET", null);
    }

    function save(priceQuote) {
      return execute(baseUrl + pathToApi + 'PriceQuote', "Post", priceQuote);
    }

    function send(mailObject) {
      return execute(baseUrl + pathToApi + 'PriceQuote/Send', "Post", mailObject);
    }

    function approve(approvalObject) {
      return execute(baseUrl + pathToApi + 'PriceQuote/Approve', "Post", approvalObject);
    }

    function cancel(priceQuoteId) {
      return execute(baseUrl + pathToApi + 'PriceQuote/Cancel?id=' + priceQuoteId, "PUT");
    }

    function pdf(id, callback) {
      var url = baseUrl + pathToApi + 'PriceQuote/Pdf?id=' + id;
      window.open(url, '_blank');
      if (callback) {
        callback();
      }
    }

    function execute(url, method, priceQuote) {
      var deferred = $q.defer();

      $http({
        url: url,
        method: method,
        cache: false,
        dataType: 'json',
        data: priceQuote ? JSON.stringify(priceQuote) : ""
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }
  }

  function GiftCardRepository($http) {
    return {
      getGiftCards: getGiftCards,
      openGiftCard: openGiftCard
    };

    function getGiftCards(searchText, from, to) {
      return execute(baseUrl + pathToApi + 'GiftCardsReport?search=' + (searchText || '') +
        '&from=' + (from || null) +
        '&to=' + (to || null), "GET", null);
    }

    function openGiftCard(giftCardId) {
      return execute(baseUrl + pathToApi + 'GiftCardsReport/GiftCard?giftCardId=' + giftCardId, "GET", null);
    }

    function execute(url, method, toSend) {
      var deferred = $q.defer();

      $http({
        url: url,
        method: method,
        dataType: 'json',
        data: toSend ? JSON.stringify(toSend) : ""
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }
  }

  function CustomTimelineItemRepository($http, httpExecuter, custom$HttpCache) {
    return {
      hide: hide,
      lock: lock,
      createOrUpdate: createOrUpdate,
      getCategories: getCategories,
      getTemplates: getTemplates,
      createTemplate: createTemplate,
      updateTemplateContent: updateTemplateContent,
      deleteTemplate: deleteTemplate,
      emailContent: emailContent,
      emailPdf: emailPdf
    };

    function hide(id) {
      return execute(baseUrl + pathToApi + 'CustomTimelineItem/HideCustomItem?id=' + id, "PUT", null);
    }

    function lock(id) {
      return execute(baseUrl + pathToApi + 'CustomTimelineItem/LockCustomItem?id=' + id, "PUT", null);
    }

    function createOrUpdate(customTimlineItem) {
      if (customTimlineItem.EntityId < 1) {
        return execute(baseUrl + pathToApi + 'CustomTimelineItem/' + customTimlineItem.EntityType, "POST", customTimlineItem).then(function (data) {
          custom$HttpCache.removeByName(customTimlineItem.EntityId + "Id");
          return data;
        });
      } else {
        return execute(baseUrl + pathToApi + 'CustomTimelineItem/Entity', "PUT", customTimlineItem).then(function (data) {
          custom$HttpCache.removeByName(customTimlineItem.EntityId + "Id");
          return data;
        });
      }
    }

    function emailContent(content) {
      return execute(baseUrl + pathToApi + 'CustomTimelineItem/EmailContent', "POST", content).then(function (data) {
        return data;
      });
    }

    function emailPdf(pdfBlob, email, fileName) {
      const formData = new FormData();
      formData.append('pdf', pdfBlob, fileName); // The second argument is the filename

      var url = baseUrl + pathToApi + "CustomTimelineItem/EmailPdf?email=" + email;
      // Use the Fetch API to send the Blob to the server
      return fetch(url, {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())  // Assuming server returns JSON response
      // .then(result => {
      //     console.log('Success:', result);
      // })
      // .catch(error => {
      //     console.error('Error:', error);
      // });


      // return execute(baseUrl + pathToApi + 'CustomTimelineItem/EmailPdf', "POST", content).then(function (data) {
      //   return data;
      // });
    }

    function getCategories(customerId) {
      return execute(baseUrl + pathToApi + 'CustomTimelineItem/CustomItemCategories' + (customerId ? '?customerId=' + customerId : ''), "GET", null);
    }

    function getTemplates() {
      return httpExecuter.execute(baseUrl + pathToODataApi + 'CustomTimelineItemPatterns');
    }

    function createTemplate(data) {
      return httpExecuter.post(baseUrl + pathToODataApi + 'CustomTimelineItemPatterns', data);
    }

    function updateTemplateContent(content) {
      return httpExecuter.patch(baseUrl + pathToODataApi + 'CustomTimelineItemPatterns', {Content: content});
    }

    function deleteTemplate(id) {
      var url = baseUrl + pathToODataApi + "CustomTimelineItemPatterns" + ('(' + id + ')');
      return httpExecuter.delete(url);
    }


    function execute(url, method, dataToPost) {
      var deferred = $q.defer();

      $http({
        url: url,
        method: method,
        dataType: 'json',
        data: dataToPost ? JSON.stringify(dataToPost) : ""
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }
  }

  function GeneralRepository($http) {
    return {
      toBase64: toBase64,
      giftCardTemplates
    };

    function toBase64(path) {
      var url = baseUrl + pathToApi + "FilesUpload/ImageToBase64?path=" + path;
      return execute(url);
    }

    function execute(url) {
      var deferred = $q.defer();

      $http({
        url: url,
        method: "GET"
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    function giftCardTemplates() {
      return $http({
        url: `${window.giftCardsURI}/api/gift-card-templates`,
        method: 'GET'
      })
    }
  }

  function CommonSubscriptionsRepository(httpExecuter, custom$HttpCache) {
    return {
      get: get,
      post: post,
      remove: remove
    };

    function get(type, id) {
      var url = baseUrl + pathToApi + 'CommonSubscriptions?itemMetadataType=' + type + '&itemMetadataId=' + id;
      return httpExecuter.execute(url, true);
    }

    function post(template) {
      var url = baseUrl + pathToApi + 'CommonSubscriptions';
      return httpExecuter.post(url, template);
    }

    function remove(id) {
      var url = baseUrl + pathToApi + 'CommonSubscriptions?id=' + id;
      return httpExecuter.delete(url);
    }
  }

  function CommonGiftCardsRepository(httpExecuter, custom$HttpCache) {
    return {
      get: get,
      post: post,
      remove: remove
    };

    function get(type, id) {
      var url = baseUrl + pathToApi + 'CommonGiftCards';
      return httpExecuter.execute(url, true);
    }

    function post(template) {
      var url = baseUrl + pathToApi + 'CommonGiftCards';
      return httpExecuter.post(url, template);
    }

    function remove(id) {
      var url = baseUrl + pathToApi + 'CommonGiftCards?id=' + id;
      return httpExecuter.delete(url);
    }
  }

  function PriceListsRepository(httpExecuter, custom$HttpCache) {
    return {
      get: get,
      post: post,
      remove: remove
    };

    function get(type, id) {
      var url = baseUrl + pathToApi + 'PriceList';
      return httpExecuter.execute(url, true);
    }

    function post(template) {
      var url = baseUrl + pathToApi + 'PriceList';
      return httpExecuter.post(url, template);
    }

    function remove(id) {
      var url = baseUrl + pathToApi + 'PriceList?id=' + id;
      return httpExecuter.delete(url);
    }
  }

  function LeadSubStatusRepository(httpExecuter, custom$HttpCache) {
    return {
      get: get,
      create: create,
      remove: remove
    };

    function get(type, id) {
      var url = baseUrl + pathToODataApi + 'LeadSubStatus';
      return httpExecuter.execute(url, true);
    }

    function create(status) {
      var url = baseUrl + pathToODataApi + 'LeadSubStatus';
      return httpExecuter.post(url, status);
    }

    function remove(id) {
      var url = baseUrl + pathToODataApi + 'LeadSubStatus(' + id + ')';
      return httpExecuter.delete(url);
    }
  }

  function LeadCategoryRepository(httpExecuter, custom$HttpCache) {
    return {
      get: get,
      create: create,
      remove: remove
    };

    function get(type, id) {
      var url = baseUrl + pathToODataApi + 'LeadCategories';
      return httpExecuter.execute(url, true);
    }

    function create(status) {
      var url = baseUrl + pathToODataApi + 'LeadCategories';
      return httpExecuter.post(url, status);
    }

    function remove(id) {
      var url = baseUrl + pathToODataApi + 'LeadCategories(' + id + ')';
      return httpExecuter.delete(url);
    }
  }


  function CalendarRepository(httpExecuter, custom$HttpCache) {
    return {
      getHolidays: getHolidays,
      getCustomersWithMeeting: getCustomersWithMeeting,
      getMeetings: getMeetings,
      updateCalendarEvent: updateCalendarEvent,
      createMeeting: createMeeting,
      updateMeeting: updateMeeting,
      updateStatus: updateStatus,
      markAsNotPaid: markAsNotPaid,
      updateColor: updateColor,
      updateRemarks: updateRemarks,
      splitMeeting: splitMeeting,
      deleteMeeting: deleteMeeting,
      uploadShifts: uploadShifts,
      daysOfWork: daysOfWork,
      syncStatus: syncStatus,
      pauseSync: pauseSync,
      generateToken: generateToken,
      performFullSync: function (metadata, publisher) {
        performSocketAction('full-sync', metadata, publisher)
      },
      performFullDelete: function (metadata, publisher) {
        performSocketAction('full-delete', metadata, publisher)
      },
      validateMeetingPosition: validateMeetingPosition,
      getServicesByCustomerAndEmployee: getServicesByCustomerAndEmployee,
      resolveResources: resolveResources,
      searchMeetings: searchMeetings,
      updateEmployeesOrder: updateEmployeesOrder,
      clientMeetingById: clientMeetingById,
      getRelatedMeetings: getRelatedMeetings,
      getOnlineBookingLink: getOnlineBookingLink,
      approveMeeting: approveMeeting,
      getAwaitingApproval: getAwaitingApproval,
      extendMeetingNotes: extendMeetingNotes,
      cancellationReasons: cancellationReasons,
      averageMonthlyMeetings: averageMonthlyMeetings,
      defaultPromotionVisitOptions: defaultPromotionVisitOptions
    };

    function getHolidays() {
      var url = baseUrl + pathToApi + "Calendar/Holidays";
      return httpExecuter.execute(url);
    }

    function getAwaitingApproval() {
      var url = baseUrl + pathToApi + "OnlineScheduling/AwaitingApproval";
      return httpExecuter.execute(url, true);
    }

    function clientMeetingById(id, startTime) {
      var url = baseUrl + pathToApi + 'Calendar/ClientMeetingById?id=' + id + "&start=" + startTime;
      return httpExecuter.execute(url, true);
    }

    function getCustomersWithMeeting() {
      var url = baseUrl + pathToApi + "Calendar/CustomersWithCloseMeetings";
      return httpExecuter.execute(url, true);
    }

    function getOnlineBookingLink(customerId) {
      var url = baseUrl + pathToApi + 'OnlineScheduling/GetLink';
      customerId = customerId || null;
      url += '?customerId=' + customerId;

      return httpExecuter.execute(url);
    }

    function getMeetings(start, end, showCancelled) {
      var url = baseUrl + pathToApi + "Calendar/Meetings?start=" + start + "&end=" + end;
      if (showCancelled) {
        url += "&includeDeactivated=true";
      }

      return httpExecuter.execute(url, true).then(function (result) {
        var finalResult = [];
        finalResult.push.apply(finalResult, result.nonRepeating);
        finalResult.push.apply(finalResult, result.repeating);
        finalResult.push.apply(finalResult, result.exceptions);
        return finalResult.map(function (meeting) {
          if (meeting.CompositeAdapters && meeting.CompositeAdapters.length > 0) {
            var toReplace = {};
            meeting.CompositeAdapters.forEach(function (composite) {
              toReplace[composite.ServiceMetadataId] = composite.Services;
            });

            meeting.CompositeAdapters = toReplace;
          } else {
            delete meeting.CompositeAdapters;
          }

          return meeting;
        });
      });
    }

    function getRelatedMeetings(start, end, meetingId) {
      var url = baseUrl + pathToApi + "Calendar/RelatedMeetings?meetingId=" + meetingId + "&start=" + start + "&end=" + end;
      return httpExecuter.execute(url, true);
    }

    function updateCalendarEvent(meeting) {
      var url = baseUrl + pathToApi + "Calendar/Event";
      return httpExecuter.post(url, meeting);
    }

    function updateEmployeesOrder(newOrder) {
      var url = baseUrl + pathToApi + 'Calendar/EmployeesOrder';
      return httpExecuter.put(url, newOrder);
    }

    function createMeeting(meeting, metadata) {
      var url = baseUrl + pathToApi + "Calendar";
      return httpExecuter.post(url, {Meeting: meeting, Metadata: metadata}, function () {
        if (meeting.NewCustomer) {
          custom$HttpCache.removeByName("CustomerId");
        }
      });
    }

    function updateMeeting(meeting, metadata) {
      var url = baseUrl + pathToApi + "Calendar";
      return httpExecuter.put(url, {Meeting: meeting, Metadata: metadata});
    }

    function updateStatus(meetingId, meetingTime, newStatus) {
      var url = baseUrl + pathToApi + "Calendar/MeetingStatus";
      return httpExecuter.put(url, {
        MeetingId: meetingId,
        MeetingTime: meetingTime,
        MeetingState: newStatus
      });
    }

    function markAsNotPaid(meetingId, meetingTime) {
      var url = baseUrl + pathToApi + "Calendar/ClearMeetingPaidStatus";
      return httpExecuter.put(url, {
        MeetingId: meetingId,
        MeetingTime: meetingTime
      });
    }

    function updateColor(meetingId, meetingTime, newColor) {
      var url = baseUrl + pathToApi + "Calendar/MeetingColor";
      return httpExecuter.put(url, {
        MeetingId: meetingId,
        MeetingTime: meetingTime,
        MeetingColor: newColor
      });
    }

    function updateRemarks(meetingId, meetingTime, remarks) {
      var url = baseUrl + pathToApi + "Calendar/MeetingRemarks";
      return httpExecuter.put(url, {
        MeetingId: meetingId,
        MeetingTime: meetingTime,
        MeetingRemarks: remarks
      });
    }

    function deleteMeeting(meeting, metadata) {
      var url = baseUrl + pathToApi + "Calendar";
      return httpExecuter.delete(url, {Meeting: meeting, Metadata: metadata});
    }

    function splitMeeting(meetingAndMetadata) {
      var url = baseUrl + pathToApi + "Calendar" + '/Split';
      return httpExecuter.put(url, meetingAndMetadata);
    }

    function uploadShifts(shiftsMetadata) {
      var url = baseUrl + pathToApi + "Calendar" + '/DaysOfWorkExceptions';
      return httpExecuter.put(url, shiftsMetadata);
    }

    function validateMeetingPosition(employeeId, date, startIndex, endIndex) {
      var url = baseUrl + pathToApi + 'Calendar/IsValidMeetingForEmployee?id=' + employeeId + '&date=' + date.format(ODATA_DATE_ONLY_FORMAT) +
        '&start=' + startIndex + '&end=' + endIndex;
      return httpExecuter.execute(url, true);
    }

    function daysOfWork(start, end, loadEmployees) {
      var url = baseUrl + pathToApi + "Calendar/DaysOfWork?start=" + start + "&end=" + end;
      if (loadEmployees) {
        url += '&loadEmployees=true';
      }

      return httpExecuter.execute(url, true);
    }

    function getServicesByCustomerAndEmployee(employeeId, customerId, meetingId) {
      var url = baseUrl + pathToApi + 'Calendar/Services?employeeId=' + employeeId + '&customerId=' + (customerId || 'null') + '&meetingId=' + meetingId;
      return httpExecuter.execute(url, true);
    }

    function extendMeetingNotes(meetingIds) {
      var url = baseUrl + pathToApi + 'Calendar/MeetingRemarks';
      return httpExecuter.post(url, meetingIds);
    }

    function resolveResources(employeeId, customerId, roomId, serviceMetadataId, mfrom, mto) {
      var url = baseUrl + pathToApi + 'Calendar/Resources?employeeId='
        + (employeeId || 'null') +
        '&customerId=' + (customerId || 'null') +
        '&roomId=' + (roomId || 'null') +
        '&serviceMetadataIds=' + (serviceMetadataId || 'null') +
        '&mfrom=' + (mfrom || 'null') +
        '&mto=' + (mto || 'null');

      return httpExecuter.execute(url, true);
    }

    function syncStatus(employeeId) {
      var deferred = $q.defer();
      generateToken(employeeId)
        .then(function (response) {
          var url = googleCalendarPath + '/api/context/status';
          httpExecuter.post(url, {token: response.token})
            .then(function (result) {
              deferred.resolve(result);
            }).catch(function (err) {
            deferred.reject(err);
          });

        })
        .catch(function (err) {
          deferred.reject(err);
        });

      return deferred.promise;

    }

    function pauseSync(employeeId) {
      var deferred = $q.defer();
      generateToken(employeeId)
        .then(function (response) {
          var url = googleCalendarPath + '/api/context/pause';
          httpExecuter.post(url, {token: response.token})
            .then(function (result) {
              deferred.resolve(result);
            }).catch(function (res) {
            deferred.reject(err);
          });

        })
        .catch(function (res) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function generateToken(employeeId) {
      var url = baseUrl + pathToApi + "GoogleCalendar/GenerateToken";
      return httpExecuter.post(url, {EmployeeId: employeeId});
    }

    function performSocketAction(action, metadata, publisher) {
      var socket = io(googleCalendarPath);
      socket.on('progress', function (res) {
        publisher.fire('sync.progress', res);
      });

      socket.on('stats', function (res) {
        publisher.fire('sync.stats', res);
      });

      socket.on('error', function (err) {
        publisher.fire('sync.error', err);
      });

      socket.on('finish', function (res) {
        publisher.fire('sync.finished', res);
        socket.disconnect();
      });

      socket.emit(action, metadata, function (res) {
        console.log(res);
      });
    }

    function searchMeetings(phrase, pivotDate) {
      var url = baseUrl + pathToApi + 'Calendar/Search?search=' + phrase + '&pivotdate=' + (pivotDate ? pivotDate : '');
      return httpExecuter.execute(url, true);
    }

    function approveMeeting(meetingId) {
      var url = baseUrl + pathToApi + 'OnlineScheduling/ApproveOnlineBooking';
      return httpExecuter.post(url, {MeetingId: meetingId, Device: 'Web'});
    }

    function cancellationReasons() {
      var url = baseUrl + pathToApi + "Calendar/CancellationReasons";
      return httpExecuter.execute(url);
    }

    function averageMonthlyMeetings(numOfMonths = 6) {
      const url = baseUrl + pathToApi + `PromotionCreator/MeetingsPerMonth?monthsBack=${numOfMonths}`
      return httpExecuter.execute(url);
    }

    function defaultPromotionVisitOptions() {
      const url = baseUrl + pathToApi + `PromotionCreator/DefaultVisitOption`
      return httpExecuter.execute(url);
    }
  }

  function SearchRepository(httpExecuter) {
    return {
      getServiceCategories,
      getSimilarity,
      findSimilarOnIndex,
      getServicesByBusinessTypes,
      getProductsByManufacturers,
      createBatchItems,
      getManufacturers,
      allItems
    };

    function getServiceCategories() {
      const url = window.indexURI + pathToApi + 'common/services-by-business-map'
      return httpExecuter.execute(url, true, true);
    }

    function getServicesByBusinessTypes(type = [], subTypes = []) {
      const url = window.indexURI + pathToApi + `services-by-types?businessType=${type.join(',')}&businessSubTypes=${subTypes.join(',')}`
      return httpExecuter.execute(url, false, true);
    }

    function getManufacturers(businessType) {
      const url = window.indexURI + pathToApi + `products/manufacturers?businessType=${businessType}`
      return httpExecuter.execute(url, false, true);
    }

    function getProductsByManufacturers(manufacturers) {
      const url = window.indexURI + pathToApi + `products-by-manufacturers?manufacturers=${manufacturers.join(',')}`
      return httpExecuter.execute(url, false, true);
    }

    function getSimilarity() {
      const url = window.aiURI + pathToApi + `index-similarity`
      return httpExecuter.execute(url, false, true);
    }

    function allItems(index) {
      const url = window.indexURI + pathToApi + `${index}/all`
      return httpExecuter.execute(url, false, true);
    }

    function findSimilarOnIndex(query, index = 'services') {
      const url = window.indexURI + pathToApi + `${index}?${index.substring(0, index.length - 1)}=${query}`
      return httpExecuter.execute(url, false, true);

    }

    function createBatchItems(items) {
      const url = baseUrl + pathToApi + "ProductOrServiceMetadataCreator/CreateBatch";
      return httpExecuter.post(url, items)

    }
  }

  function CustomersGroupRepository(httpExecuter) {
    return {
      create: createOrUpdate,
      update: createOrUpdate,
      get: get
    };

    function get(groupId) {
      var url = baseUrl + pathToODataApi + "Customers/?$filter=CustomerId%20eq%20" + groupId +
        "&$expand=Customers,Customers/Images&$select=Customers/CustomerId,Customers/FirstName,Customers/LastName,Customers/DefaultImagePath,Customers/Gender";
      return httpExecuter.execute(url);
    }

    function createOrUpdate(group) {
      var deferred = $q.defer();

      var url = baseUrl + pathToODataApi + "Customers/Group";
      httpExecuter.post(url, group)
        .then(function (res) {
          res.EntityType = "Customer";
          deferred.resolve(res);
        })
        .catch(deferred.reject);

      return deferred.promise;
    }
  }

  function LeadsRepository(httpExecuter, $q) {
    return {
      transformIntoCustomer: transformIntoCustomer,
      mergeIntoCustomer: mergeIntoCustomer,
      updateBatch: updateBatch,
      exportToExcel: exportToExcel,
      info: info
    };

    function transformIntoCustomer(leadId) {
      var deferred = $q.defer();

      var url = baseUrl + pathToODataApi + 'Customers/Lead' + '?id=' + leadId;
      httpExecuter.post(url, {})
        .then(function (res) {
          res.EntityType = "Customer";
          deferred.resolve(res);
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

    function mergeIntoCustomer(leadId) {
      var deferred = $q.defer();

      var url = baseUrl + pathToODataApi + 'Customers/MergeLead' + '?id=' + leadId;
      httpExecuter.post(url, {})
        .then(function (res) {
          res.EntityType = "Customer";
          deferred.resolve(res);
        })
        .catch(deferred.reject);

      return deferred.promise;
    }


    function updateBatch(leads) {
      var deferred = $q.defer();
      var url = baseUrl + pathToODataApi + "Leads/UpdateBatch";
      httpExecuter.post(url, leads)
        .then(function (res) {
          deferred.resolve(res);
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

    function exportToExcel(query) {
      var url = baseUrl + pathToApi + "Reports/ExportLeads" + query;
      window.location = url;
    }

    function info(query) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + 'Reports/LeadsInfo' + query;
      httpExecuter.execute(url)
        .then(function (res) {
          deferred.resolve(res);
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

  }

  function MembershipRepository(httpExecuter, custom$HttpCache) {
    return {
      createOrUpdate: createOrUpdate,
      assignToCustomer: assignToCustomer,
      get: get,
      cancel: cancel
    };

    function createOrUpdate(membership) {
      var deferred = $q.defer();

      var url = baseUrl + pathToApi + "Membership";
      httpExecuter.post(url, membership)
        .then(function (res) {
          deferred.resolve(res);
          custom$HttpCache.removeByName("CustomerId");
          custom$HttpCache.removeByName("Membership");
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

    function assignToCustomer(membership) {
      var deferred = $q.defer();

      var url = baseUrl + pathToApi + "Membership/Assign";
      httpExecuter.post(url, membership)
        .then(function (res) {
          deferred.resolve(res);
          custom$HttpCache.removeByName("CustomerId");
          custom$HttpCache.removeByName("Membership");
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

    function get(customerId) {
      var url = baseUrl + pathToApi + "Membership?customerId=" + customerId;
      return httpExecuter.execute(url);
    }

    function cancel(membershipId) {
      custom$HttpCache.removeByName("CustomerId");
      custom$HttpCache.removeByName("Membership");
      var url = baseUrl + pathToApi + "Membership?membershipId=" + membershipId;
      return httpExecuter.delete(url);
    }
  }

  function VersionRepository(httpExecuter) {
    return {
      appVersion: appVersion
    };

    function appVersion() {
      var url = baseUrl + pathToApi + "Configuration/Version";
      return httpExecuter.execute(url);
    }
  }

  function TemplateMessagesRepository(httpExecuter, custom$HttpCache) {
    return {
      get: getMessages,
      post: postMessage,
      put: putMessage,
      delete: deleteMessage,
      getMessageContent: getMessageContent
    };

    function getMessages() {
      var url = baseUrl + pathToODataApi + "MessageTemplates";
      return httpExecuter.execute(url);
    }

    function postMessage(message) {
      var deferred = $q.defer();

      var url = baseUrl + pathToODataApi + "MessageTemplates";
      httpExecuter.post(url, message)
        .then(function (res) {
          deferred.resolve(res);
          custom$HttpCache.removeByName("MessageTemplateId");
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

    function putMessage(message) {
      var deferred = $q.defer();

      var url = baseUrl + pathToODataApi + "MessageTemplates" + ('(' + message.MessageTemplateId + ')');
      httpExecuter.put(url, message)
        .then(function (res) {
          deferred.resolve(res);
          custom$HttpCache.removeByName("MessageTemplateId");
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

    function deleteMessage(id) {
      var deferred = $q.defer();
      var url = baseUrl + pathToODataApi + "MessageTemplates" + ('(' + id + ')');

      httpExecuter.delete(url)
        .then(function (res) {
          deferred.resolve(res);
          custom$HttpCache.removeByName("MessageTemplateId");
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

    function getMessageContent(message) {
      var deferred = $q.defer();

      var url = baseUrl + pathToApi + "SmsServer/MessageTemplate";
      httpExecuter.post(url, message)
        .then(function (res) {
          deferred.resolve(res);
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

  }

  function EmployeeSalesRepository(httpExecuter) {
    return {
      get: get,
      exportToExcel: exportToExcel
    };

    function get(id, from, to, groupByManufacturer) {
      var url = baseUrl + pathToApi + "EmployeeSales?id=" + id + "&from=" + from + "&to=" + to + "&groupByManufacturer=" + groupByManufacturer;
      return httpExecuter.execute(url);
    }

    function exportToExcel(id, from, to, groupByManufacturer) {
      var url = baseUrl + pathToApi + "EmployeeSales/Export?id=" + id + "&from=" + from + "&to=" + to + "&groupByManufacturer=" + groupByManufacturer;
      window.location = url;
    }
  }

  function ResourceManagementRepository(httpExecuter) {
    var mapper = {
      employeeType: {resource: 'EmployeeTypeServices', ids: 'ServiceMetadataIds'},
      room: {resource: 'RoomServices', ids: 'ServiceMetadataIds'},
      employee: {resource: 'EmployeeTypesAssignment', ids: 'EmployeeTypeIds'}
    };

    return {
      update: function (entityType, id, idsToUpdate) {
        var toUpdate = {};
        toUpdate[mapper[entityType].ids] = idsToUpdate;
        return update(mapper[entityType].resource, id, toUpdate);
      }
    };

    function update(resource, id, toUpdate) {
      var url = baseUrl + pathToApi + 'ResourcesManager/' + resource + '/' + id;
      return httpExecuter.put(url, toUpdate);
    }

  }

  function ExpensesRepository(httpExecuter, custom$HttpCache) {
    return {
      getTotal: getTotal,
      export: exportExpenses
    };

    function getTotal(query) {
      var url = baseUrl + pathToApi + 'ExpensesManager/Total?' + query;
      return httpExecuter.execute(url);
    }

    function exportExpenses(query) {
      var url = baseUrl + pathToApi + 'ExpensesManager/Export?' + query;
      window.location = url;
    }

  }

  function TutorialsRepository(httpExecuter, custom$HttpCache) {
    return {
      getTutorials: getTutorials
    };

    function getTutorials(view) {
      var url = adminServerURL + 'api/tutorials/view/' + view;
      return httpExecuter.execute(url);
    }

  }

  function CustomerSubscriptionRepository(httpExecuter) {
    return {
      create: create
    };

    function create(punchcard) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "CustomerSubscription";
      httpExecuter.post(url, punchcard)
        .then(function (res) {
          deferred.resolve(res);
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

  }

  function IncomingCallsRepository(httpExecuter) {
    return {
      handleMultiple: handleMultiple,
      handleAll: handleAll
    };

    function handleMultiple(ids) {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "Calls/HandleMultiple";
      httpExecuter.put(url, {Keys: ids})
        .then(function (res) {
          deferred.resolve(res);
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

    function handleAll() {
      var deferred = $q.defer();
      var url = baseUrl + pathToApi + "Calls/HandleAll";
      httpExecuter.put(url, {})
        .then(function (res) {
          deferred.resolve(res);
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

  }

  function OnlineScheduling(httpExecuter, cache, $q) {
    return {
      createOrUpdateOnlineService: createOrUpdateOnlineService,
      createOrUpdateOnlineServicesBatch: createOrUpdateOnlineServicesBatch,
      removeOnlineService: removeOnlineService,
      updateMultipleServices: updateMultipleServices,
      loadAvailabilities: loadAvailabilities,
      createOrUpdateAvailability: createOrUpdateAvailability,
      alignBusinessHoursWithOnlineBooking: alignBusinessHoursWithOnlineBooking,
      deleteAvailability: deleteAvailability,
      loadConfigurations: loadConfigurations,
      saveConfigurationsAndEmployees: saveConfigurationsAndEmployees,
      loadEmployees: loadEmployees
    };

    function createOrUpdateOnlineService(service) {
      if (!service.OnlineBookingServiceMetadataId) {
        return createOnlineService(service);
      }

      var url = baseUrl + pathToApi + "OnlineScheduling/UpdateOnlineService";
      return httpExecuter.put(url, service);
    }

    function createOnlineService(service) {
      var url = baseUrl + pathToApi + "OnlineScheduling/CreateOnlineService";
      return httpExecuter.post(url, service);
    }

    function createOrUpdateOnlineServicesBatch(services) {
      var url = baseUrl + pathToApi + "OnlineScheduling/CreateMultipleOnlineServices";
      return httpExecuter.post(url, services);
    }

    function removeOnlineService(service) {
      var url = baseUrl + pathToApi + "OnlineScheduling/RemoveOnlineService?OnlineBookingServiceId=" + service.OnlineBookingServiceMetadataId;
      return httpExecuter.delete(url);
    }

    function updateMultipleServices(services) {
      var url = baseUrl + pathToApi + "OnlineScheduling/UpdateOnlineServices";
      return httpExecuter.put(url, services);
    }

    function loadAvailabilities(startDate, endDate) {
      var url = baseUrl + pathToApi + "OnlineScheduling/Availabilities?" + "start=" + startDate + "&end=" + endDate;
      return httpExecuter.execute(url);
    }

    function createOrUpdateAvailability(data) {
      var url = baseUrl + pathToApi + "OnlineScheduling/" + (!data.Availability.OnlineBookingAvailabilityId ? 'CreateAvailability' : 'UpdateAvailability');
      return httpExecuter[!data.Availability.OnlineBookingAvailabilityId ? 'post' : 'put'](url, data);
    }

    function alignBusinessHoursWithOnlineBooking() {
      var url = baseUrl + pathToApi + "OnlineScheduling/AlignBusinessHoursWithOnlineBooking";
      return httpExecuter.post(url, {});
    }

    function deleteAvailability(data) {
      var url = baseUrl + pathToApi + "OnlineScheduling/DeleteAvailability";
      return httpExecuter.delete(url, data);
    }

    function loadConfigurations() {
      var url = baseUrl + pathToApi + "OnlineScheduling/Configurations";
      return httpExecuter.execute(url, true);
    }

    function saveConfigurationsAndEmployees(config) {
      var url = baseUrl + pathToApi + "OnlineScheduling/UpdateConfigurations";
      return httpExecuter.put(url, config);
    }

    function loadEmployees() {
      var url = baseUrl + pathToApi + "OnlineScheduling/Employees";
      return httpExecuter.execute(url);
    }
  }


  function Forms(httpExecuter) {
    return {
      getForms: getForms,
      formsApi: formsApi
    };

    function getForms() {
      const url = formsURI + "/" + pathToApi + 'forms-for-context';
      return httpExecuter.execute(url, true, true)
    }


    function formsApi() {
      return {
        get: () => {
          return getForms().then(forms => forms.map(({name, _id}) => ({name, value: _id})));
        }
      };
    }


  }

  function WhatsNew(httpExecuter, $http) {
    return {
      whatsNew: getWhatsNew,
      gotIt: gotIt
    };

    function getWhatsNew() {
      return httpExecuter.execute(whatsNewURI, true, true);
    }

    function gotIt(version) {
      return httpExecuter.post(whatsNewURI, {version, platform: 'web'}, null, true);
    }
  }

  function PCI(httpExecuter) {
    return {
      terminalsAndPinpads,
      charge,
      cancel,
      convertToToken,
      customerToken,
      deleteCustomerToken
    };

    function terminalsAndPinpads() {
      return httpExecuter.execute(`${pciURI}/api/terminals-and-pinpads`, true, true);
    }

    function charge(transaction) {
      return httpExecuter.post(`${pciURI}/api/charge`, transaction, null, true);
    }

    function cancel(paymentId) {
      return httpExecuter.delete(`${pciURI}/api/charge/${paymentId}`, null, true);
    }

    function convertToToken(creditCardDetails, customerId) {
      return httpExecuter.post(`${pciURI}/api/deferred-charges/token`, {
        creditCardDetails, customerId
      }, null, true);
    }

    function customerToken(customerId, bindInternalToken) {
      return httpExecuter.execute(`${pciURI}/api/deferred-charges/token/${customerId}${isNonEmptyString(bindInternalToken) ? `?bindInternalToken=${bindInternalToken}` : ''}`,
        true, true);
    }

    function deleteCustomerToken(customerId) {
      return httpExecuter.delete(`${pciURI}/api/deferred-charges/token/${customerId}`, null, true);
    }

  }

  function VOIP(httpExecuter) {
    return {
      call,
      calls,
    };

    function call(body) {
      return httpExecuter.post(`${voipUrl}/api/call`, body, null, true);
    }

    function calls(operator, destination) {
      const {vendor} = operator;
      const uri = `${voipUrl}/api/calls?vendor=${vendor}&destination=${destination}`;
      const query = new Query(() => uri, true);

      query.get = function () {
        return httpExecuter.execute(query.getQuery(), true, true);
      };

      return query;
    }
  }

  function Themes(httpExecuter) {
    return {
      getThemes,
      deleteTheme,
      setTheme
    };

    function getThemes() {
      return httpExecuter.execute(`${themesUrl}/api/themes`, true, true);
    }

    function deleteTheme() {
      return httpExecuter.delete(`${themesUrl}/api/themes`, true, true);
    }

    function setTheme(theme) {
      return httpExecuter.post(`${themesUrl}/api/themes`, theme, null, true);
    }
  }

  function SessionsRepository(httpExecuter) {
    const sessionUri = `${sessionsURI}/api/session`;
    const sessionsUri = `${sessionsURI}/api/sessions`;
    const priceQuotesUri = `${sessionsURI}/api/price-quotes`;

    return {
      getSession,
      setSession,
      clearSession,
      closeSession,
      getPriceQuote,
      createOrUpdatePaymentLink,
      getPaymentLinks,
    };

    function getSession(meetingId, date, customerId) {
      return httpExecuter.execute(toURI(meetingId, date, customerId), true, true);
    }

    function getPriceQuote(priceQuoteId) {
      return httpExecuter.execute(`${priceQuotesUri}/${priceQuoteId}`, true, true);
    }

    function setSession(data, meetingId, date, customerId, sessionId) {
      return httpExecuter.post(toURI(meetingId, date, customerId, sessionId), data, null, true);
    }

    function clearSession(sessionId) {
      return httpExecuter.delete(`${sessionUri}/${sessionId}`, null, true);
    }

    function closeSession(sessionId) {
      return httpExecuter.put(`${sessionsUri}/${sessionId}/done`, {}, true);
    }

    function createOrUpdatePaymentLink(sessionId, data) {
      return httpExecuter.post(`${sessionsUri}/${sessionId}/payment-link`, data, null, true);
    }

    function toURI(meetingId, meetingDate, customerId, sessionId) {
      let adaptedQuery;
      if (isNonEmptyString(sessionId)) {
        adaptedQuery = `sessionId=${sessionId}`;
      } else {
        const query = cleanObj({meetingId, customerId, relativeDate: meetingDate});
        adaptedQuery = Object.keys(query).map((queryKey) => `${queryKey}=${query[queryKey]}`).join('&');
      }

      return `${sessionUri}?${adaptedQuery}`;
    }

    function getPaymentLinks(status, customerName, top, skip = 0) {
      let url = `${sessionsUri}?hasPaymentLink=true&limit=${top}&page=${Math.floor(skip / top) + 1}`;
      if (status) {
        url += `&status=${status}`;
      }

      if (isNonEmptyString(customerName)) {
        url += `&customerName=${customerName}`;
      }

      return httpExecuter.execute(url, true, true);
    }
  }

  function ConversationsRepository(httpExecuter) {
    return {
      unreadCount,
      templates,
      createConversation,
      globalAccount,
      globalTemplates,
      sendingLimit,
      sendTemplate
    };

    function unreadCount() {
      return httpExecuter.execute(`${conversationsURI}/api/conversations/my/unread/messages`, true, true).then((res) => {
        return res.unreadCount;
      });
    }

    function templates(lang) {
      return httpExecuter.execute(`${conversationsURI}/api/promotion-wa-templates?promotionTemplates=true${isNonEmptyString(lang) ? `&language=${lang}` : ''}`, true, true)
    }

    function globalAccount() {
      return httpExecuter.execute(`${conversationsURI}/api/global-conversations/wa-account`, true, true)
    }

    function globalTemplates() {
      return httpExecuter.execute(`${conversationsURI}/api/global-conversations/templates`, true, true)

    }

    function createConversation(customerPhoneNumber, customerName, customerId, customerImage, customerType) {
      return httpExecuter.post(`${conversationsURI}/api/conversations`, cleanObj({
        customerPhoneNumber,
        customerName,
        customerId,
        customerImage,
        customerType
      }), null, true);
    }

    function sendTemplate(customerPhoneNumber, customerName, templateName, templateValues = [], customerType = 'customer') {
      return httpExecuter.post(`${conversationsURI}/api/conversations/message/template/client`, cleanObj({
        customerPhoneNumber,
        templateWAName: templateName,
        customerName,
        templateValues,
        customerType,
        flexibleTemplateWAName: true,
      }), null, true);
    }

    function sendingLimit() {
      return httpExecuter.execute(`${conversationsURI}/api/daily-limits`, true, true)
    }
  }

  function PublicApiRepository(httpExecuter) {
    return {
      getToken,
      enable
    };

    function getToken() {
      return httpExecuter.execute(`${publicApiURI}/private/token`, true, true);
    }

    function enable(contextId, contextName) {
      return httpExecuter.post(`${publicApiURI}/private/toggle-api-enablement/${contextId}`, {
        isEnabled: true,
        contextName: contextName.toLowerCase()
      }, true, true);
    }
  }


}
